import { NetworkStatus, useQuery } from '@apollo/client';
import { Grid, useMediaQuery } from '@mui/material';
import { GET_RFQ_BY_ID_QUERY, RFQQueryResponse, RFQQueryVariables } from 'graphql/query/rfqs';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import BOQCreationSection from 'components/RFQs/Sections/BOQCreation';
import GoNogoSection from 'components/RFQs/Sections/GoNogoValidation';
import RFQSection from 'components/RFQs/Sections/RFQSection';

const RFQDetailsPage = () => {
  const { rfqId = '' } = useParams<{ rfqId: string }>();

  const {
    data: rfq,
    networkStatus,
    refetch,
  } = useQuery<RFQQueryResponse, RFQQueryVariables>(GET_RFQ_BY_ID_QUERY, {
    variables: {
      id: rfqId,
    },
    nextFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    refetch();
  }, [rfqId]);

  const loadingRFQ =
    NetworkStatus.loading === networkStatus ||
    NetworkStatus.setVariables === networkStatus ||
    !!!rfq;

  const isMobileScreen = useMediaQuery('(max-width:600px)');

  return (
    <Navbar
      goBackButtonConfig={{
        title: 'RFQ Details',
      }}
    >
      {loadingRFQ ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Grid
          item
          container
          direction="column"
          xs={12}
          md={11}
          lg={9}
          xl={7}
          mb={5}
          rowGap={2.5}
          mr={isMobileScreen ? -8 : 0}
        >
          <RFQSection rfq={rfq.getRFQById} />
          <GoNogoSection rfq={rfq.getRFQById} rfqId={rfqId} refetchRFQ={refetch} />
          {(rfq.getRFQById?.rfqStatus !== 'CREATED' || rfq.getRFQById?.rfqStatus === null) && (
            <BOQCreationSection rfqId={rfqId} rfq={rfq.getRFQById} refetchRFQ={refetch} />
          )}
        </Grid>
      )}
    </Navbar>
  );
};

export default RFQDetailsPage;
