import VerifiedIcon from '@mui/icons-material/Verified';
import { Chip, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LeadStatusEnum } from 'types';

import LeadCard from 'components/Card/LeadCard';

import { getDate } from 'utils/formatHelper';
import { getEnumKeyFromValue } from 'utils/transformFn';

enum LeadScopeEnum {
  'GC' = 'GC',
  'C&I' = 'C_AND_I',
  STRUCTURE = 'STRUCTURE',
  'MEP' = 'MEP',
}

export enum RFQStatusEnum {
  CREATED = 'CREATED',
  'APPROVAL IN PROGRESS' = 'APPROVAL_IN_PROGRESS',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

const RFQListView: React.FC<{ rfqs: any[]; rfqType?: string }> = ({ rfqs }) => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      direction="row"
      columns={3}
      rowSpacing={3}
      columnSpacing={5}
      alignItems="center"
      mb={5}
      pr={2}
      xs={12}
    >
      {rfqs?.map((rfq, rfqIndex) => (
        <Grid item key={rfqIndex} mb={1.5} lg={1} xs={12} width={'100%'}>
          <LeadCard
            onClick={() => navigate(`${rfq._id}`)}
            segment={rfq?.segment}
            title={rfq?.name}
            key={rfqIndex}
          >
            <Grid
              item
              container
              xs={12}
              direction={'row'}
              color={'gray'}
              justifyContent={'space-between'}
              my={0.5}
              mb={1}
            >
              <Grid item>
                <Typography variant="caption" fontWeight={700}>
                  {getEnumKeyFromValue(LeadStatusEnum, rfq?.leadStatus)}
                </Typography>
              </Grid>
              <Grid item>
                {rfq.leadStatus === LeadStatusEnum['RFP RECEIVED'] && rfq.rfpReceivedDate ? (
                  <Typography variant="caption" fontWeight={700}>
                    {getDate(rfq.rfpReceivedDate, 'DD MMM YYYY')}
                  </Typography>
                ) : (
                  <Typography variant="caption" fontWeight={700}>
                    {getDate(rfq.rfpSubmittedDate, 'DD MMM YYYY')}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Typography variant="caption" fontWeight={700} color="gray">
              {getEnumKeyFromValue(RFQStatusEnum, rfq?.rfqStatus)}
            </Typography>

            <Grid
              sx={{
                position: 'absolute',
                right: '3%',
                bottom: 25,
              }}
            >
              <Grid container justifyContent="center" alignContent="center" rowGap={0.5}>
                <Grid item xs={12}>
                  <Stack direction="row" alignContent="center" justifyContent="center" spacing={1}>
                    {rfq?.rfqStatus === RFQStatusEnum.APPROVED && (
                      <VerifiedIcon fontSize="small" color="secondary" />
                    )}

                    <Chip
                      label={getEnumKeyFromValue(LeadScopeEnum, rfq.scope)}
                      variant="outlined"
                      color="secondary"
                      sx={{
                        fontSize: '10px',
                        height: '25px',
                        borderWidth: '1.5px',
                        '.MuiChip-label': {
                          fontWeight: 600,
                        },
                      }}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </LeadCard>
        </Grid>
      ))}
      <Grid container justifyContent="center" alignContent="center" height="25vh">
        {rfqs.length === 0 && (
          <Typography
            variant="body2"
            fontWeight="500"
            color="textSecondary"
            textAlign="center"
            alignContent={'center'}
          >
            No RFQs to show
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default RFQListView;
