import { gql } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { UOMEnum } from 'types';
import { LeadStatusEnum } from 'types';

import MultiSectionCard from 'components/Section/MultiSectionCard';

import { getEnumKeyFromValue } from 'utils/transformFn';

export const UPDATE_GONOGO_MUTATION = gql`
  mutation UpdateGoNogo($input: UpdateGoNogoInput!) {
    updateGoNogo(input: $input) {
      _id
      lead {
        name
        _id
      }
      totalAreaForWork
      uom
      approxWorkValue
      executionTime
      advance
      onSupply
      ra
      handoverPercentage
      dlpYears
      dlpRetentionPercentage
      totalHandoverPercentage
      timeToDesign
      designRequirement
      designInvestmentRequired
      emd
      abg
      pbg
      otherBG
      paymentTermsNegotiable
      comments
      reasonForNogo
    }
  }
`;

export const CREATE_GONOGO_MUTATION = gql`
  mutation CreateGoNogo($input: CreateGoNogoInput!) {
    createGoNogo(input: $input) {
      _id
      referenceId
      lead {
        name
        referenceId
        _id
      }
      totalAreaForWork
      uom
      approxWorkValue
      executionTime
      advance
      onSupply
      ra
      handoverPercentage
      dlpYears
      dlpRetentionPercentage
      totalHandoverPercentage
      timeToDesign
      designRequirement
      designInvestmentRequired
      emd
      abg
      pbg
      otherBG
      paymentTermsNegotiable
      comments
      reasonForNogo
      createdAt
      updatedAt
    }
  }
`;
export const GET_RFQ_VALIDATION_BY_LEADID = gql`
  query GetRFQValidationByLeadId($leadId: ID!) {
    getRFQValidationByLeadId(leadId: $leadId) {
      _id
      referenceId
      lead {
        name
        company {
          name
          type
        }
      }
      totalAreaForWork
      uom
      approxWorkValue
      executionTime
      advance
      onSupply
      ra
      handoverPercentage
      dlpYears
      dlpRetentionPercentage
      totalHandoverPercentage
      timeToDesign
      designRequirement
      designInvestmentRequired
      emd
      abg
      pbg
      otherBG
      paymentTermsNegotiable
      comments
      reasonForNogo
    }
  }
`;

const GoNogoSection: React.FC<{ rfqId: string; refetchRFQ: any; rfq: any }> = ({
  rfqId,
  refetchRFQ,
  rfq,
}) => {
  const goNogoData = rfq?.rfqValidation;

  const goNogoDetails: any[] = [
    {
      label: 'Total Area for work',
      value: `${goNogoData?.totalAreaForWork} ${getEnumKeyFromValue(UOMEnum, goNogoData?.uom)}`,
      type: 'STRING',
      key: 'totalAreaForWork',
    },
    {
      label: 'Approx work value in Cr',
      value: `${goNogoData?.approxWorkValue} Cr`,
      type: 'STRING',
    },
    {
      label: 'Execution Time In Months',
      value: goNogoData?.executionTime,
      type: 'STRING',
    },
    {
      label: 'Payment Terms',
      value: [
        {
          label: 'Advance %',
          value: `${goNogoData?.advance}`,
          type: 'STRING',
        },
        {
          label: 'On Supply %',
          value: goNogoData?.onSupply,
          type: 'STRING',
        },
        {
          label: 'RA %',
          value: goNogoData?.ra,
          type: 'STRING',
        },
        {
          label: 'On handover %',
          value: goNogoData?.handoverPercentage,
          type: 'STRING',
        },
        {
          label: 'DLP in years',
          value: goNogoData?.dlpYears,
          type: 'STRING',
        },
        {
          label: 'DLP retention %',
          value: goNogoData?.dlpRetentionPercentage,
          type: 'STRING',
        },
        {
          label: 'How much total do we get on handover %',
          value: goNogoData?.totalHandoverPercentage,
          type: 'STRING',
        },
        {
          label: 'Design Requirement',
          value: goNogoData?.designRequirement ? 'Yes' : 'No',
          type: 'STRING',
        },
        {
          label: 'Any Design Investment required',
          value: goNogoData?.designInvestmentRequired ? 'Yes' : 'No',
          type: 'STRING',
        },
        { label: 'Time to design', value: goNogoData?.timeToDesign, type: 'STRING' },
        { label: 'Any EMD', value: goNogoData?.emd ? 'Yes' : 'No', type: 'STRING' },

        { label: 'ABG % Value', value: `${goNogoData?.abg ?? 0}`, type: 'STRING' },
        { label: 'PBG % Value', value: `${goNogoData?.pbg ?? 0}`, type: 'STRING' },
        {
          label: 'Any other BG',
          value: goNogoData?.otherBG,
          type: 'STRING',
        },
        {
          label: 'Are payment terms negotiable',
          value: goNogoData?.paymentTermsNegotiable ? 'Yes' : 'No',
          type: 'STRING',
        },
        {
          label: 'Comments',
          value: goNogoData?.comments,
          type: 'STRING',
        },
      ],
      type: 'SECTION',
    },
  ];

  const navigate = useNavigate();

  return (
    <MultiSectionCard
      title="Client Validation"
      collapsible
      defaultCollapsed
      data={
        goNogoData
          ? goNogoDetails
          : [
              {
                label: 'Status',
                value: 'Need to perform the client validation',
                type: 'STRING',
              },
            ]
      }
      action={
        rfq.leadStatus === LeadStatusEnum['RFP RECEIVED'] &&
        (goNogoData ? (
          <Tooltip title="Update Client Validation">
            <EditNoteIcon
              onClick={() => navigate(`/rfqs/${rfqId}/client-validation?type=edit`)}
              sx={{ cursor: 'pointer' }}
            />
          </Tooltip>
        ) : (
          <Tooltip title="Add Client Validation">
            <AddIcon
              onClick={() => navigate(`/rfqs/${rfqId}/client-validation?type=create`)}
              sx={{ cursor: 'pointer' }}
            />
          </Tooltip>
        ))
      }
    />
  );
};

export default GoNogoSection;
