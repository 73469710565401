import {
  FormControl,
  InputAdornment,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

import LoadingIndicator from 'components/LoadingIndicator';

import { phoneNumberRegex } from 'utils/regexes';

type TextFieldProps = {
  loading?: boolean;
  setError?: (arg: any) => void;
} & MuiTextFieldProps;

type NumberInputFieldProps = {
  loading?: boolean;
  isPercentage?: boolean;
  showErrorBgColor?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setError?: (error: boolean) => void;
} & MuiTextFieldProps;

const TextField: React.FC<TextFieldProps> = ({ loading = false, ...props }) => {
  return (
    <FormControl fullWidth>
      <MuiTextField
        FormHelperTextProps={{
          sx: {
            ml: 0.5,
          },
        }}
        {...props}
        InputProps={
          loading
            ? {
                endAdornment: (
                  <InputAdornment position="start">
                    <LoadingIndicator size="0.9rem" />
                  </InputAdornment>
                ),
              }
            : props.InputProps
        }
      />
    </FormControl>
  );
};

export const ContactTextField: React.FC<TextFieldProps> = ({
  loading = false,
  onChange: handleChange,
  setError,
  ...props
}) => {
  const [isInvalid, toggleInvalid] = useState(false);

  const checkVal = (val: string) => {
    if (!!val && !phoneNumberRegex.test(String(val))) {
      toggleInvalid(true);
      setError && setError(true);
    } else {
      toggleInvalid(false);
      setError && setError(false);
    }
  };

  return (
    <FormControl fullWidth>
      <MuiTextField
        {...props}
        onChange={e => {
          if (handleChange) {
            if (/^[0-9]+$/.test(e.target.value)) {
              handleChange(e);
            } else {
              e.target.value = '';
              handleChange(e);
            }
            checkVal(e.target.value);
          }
        }}
        type="tel"
        error={isInvalid}
        InputProps={
          loading
            ? {
                endAdornment: (
                  <InputAdornment position="start">
                    <LoadingIndicator size="0.9rem" />
                  </InputAdornment>
                ),
              }
            : undefined
        }
      />
      {isInvalid && (
        <Typography color="crimson" fontSize={11} ml={0.5}>
          Please enter valid number
        </Typography>
      )}
    </FormControl>
  );
};

export const NumberInputField: React.FC<NumberInputFieldProps> = ({
  loading = false,
  isPercentage = false,
  showErrorBgColor = false,
  onChange: handleChange,
  setError,
  ...props
}) => {
  const [isInvalid, toggleInvalid] = useState(false);

  // Regex for valid number input
  const numberRegex = isPercentage
    ? /^(100(\.0{1,2})?|[0-9]{1,2}(\.[0-9]{1,2})?)$/
    : /^[0-9]+(\.[0-9]{1,2})?$/;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;

    // Check if the value is valid so far (e.g., allow intermediate states like "." or "0.")
    if (!/^[0-9]*\.?[0-9]*$/.test(val)) {
      return; // Ignore invalid characters without clearing the field
    }

    // Validate the complete value based on the regex
    if (handleChange) {
      handleChange(e); // Trigger parent change handler
    }

    if (val === '' || numberRegex.test(val)) {
      toggleInvalid(false);
      setError && setError(false);
    } else {
      toggleInvalid(true);
      setError && setError(true);
    }
  };

  return (
    <FormControl fullWidth>
      <MuiTextField
        {...props}
        onChange={handleInputChange}
        type="text"
        error={isInvalid}
        InputProps={
          loading
            ? {
                endAdornment: (
                  <InputAdornment position="start">
                    <LoadingIndicator size="0.9rem" />
                  </InputAdornment>
                ),
              }
            : props.InputProps
        }
        sx={{
          backgroundColor: showErrorBgColor && isInvalid ? '#db3d3d5e' : undefined,
        }}
      />
      {isInvalid && showErrorBgColor === false && (
        <Typography color="crimson" fontSize={11} ml={0.5}>
          Please enter a valid number
          {isPercentage ? ' (0 to 100 with up to 2 decimals)' : ' (positive with up to 2 decimals)'}
        </Typography>
      )}
    </FormControl>
  );
};

export default TextField;
