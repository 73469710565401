import { gql, useLazyQuery, useMutation } from '@apollo/client';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { LoadingButton } from '@mui/lab';
import { Box, FormControl, Grid, IconButton, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {
  USERS_NAMES_QUERY,
  UserNamesQueryResponse,
  UserNamesQueryVariables,
} from 'graphql/query/area';
import {
  GET_FOLDERS_QUERY_FOR_LV,
  GetFoldersQueryResponse,
  GetFoldersQueryVariables,
} from 'graphql/query/folder';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DateRangeCalender } from 'routes/Dashboard/PerformanceDashboard';
import {
  ComplianceChecklist,
  ComplianceChecklistStatus,
  DepartmentEnum,
  FolderTypeEnum,
  FrequencyEnum,
} from 'types';

import Fieldset from 'components/Fieldset';
import AutocompleteWithFetch, {
  MultiSelectAutocomplete,
} from 'components/FormPanel/AutoCompleteWithFetch';
import { Select } from 'components/Inputs/Select';
import { FolderNameEnum } from 'components/PQs/Sections/FolderSection';
import { SimplePopup } from 'components/Popup';

import { removeEmptyFields } from 'utils/common';
import { formatDate, transformCustomerNames } from 'utils/transformFn';

import { frequencyRespectiveDays } from '../StandardComplianceChecklist/StandardComplianceChecklistTable';

const CREATE_COMPLIANCE_CHECKLIST = gql`
  mutation CreateComplianceChecklist($input: [CreateComplianceChecklistInput]!) {
    createComplianceChecklist(input: $input) {
      _id
      docName
      docType {
        _id
        name
      }
      frequency
      assignTo {
        empId
        firstName
        lastName
      }
      project {
        _id
      }
      scheduleStarts
      scheduleEnds
      status
    }
  }
`;

type createComplianceChecklistMutationResponse = {
  createComplianceChecklist: ComplianceChecklist;
};

type createComplianceChecklistMutationVariables = {
  input: {
    docName: string;
    docType: string;
    frequency: FrequencyEnum;
    assignTo: string[];
    project: string;
    scheduleStarts: string;
    scheduleEnds: string;
  }[];
};

const UPDATE_COMPLIANCE_CHECKLIST_MUTATION = gql`
  mutation UpdateComplianceChecklist($input: UpdateComplianceChecklistInput!) {
    updateComplianceChecklist(input: $input) {
      _id
      referenceId
      docName
      docType {
        _id
        name
      }
      frequency
      assignTo {
        _id
        firstName
        empId
        lastName
      }
      project {
        _id
        name
      }
      scheduleStarts
      scheduleEnds
      status
    }
  }
`;

type UpdateComplianceChecklistResponse = {
  updateComplianceChecklist: ComplianceChecklist;
};

type UpdateComplianceChecklistVariables = {
  input: {
    _id: string;
    docName?: string;
    docType?: string;
    frequency?: FrequencyEnum;
    assignTo?: string[];
    project?: string;
    scheduleStarts?: string;
    scheduleEnds?: string;
    status: ComplianceChecklistStatus;
  };
};

export const SingleComplianceChecklistForm: React.FC<{
  initialData?: Record<string, any>;
  cb: (arg: any) => void;
}> = ({ initialData = {}, cb }) => {
  const isUpdateForm = !!initialData?._id;

  const { projectId = '', complianceId = '' } = useParams<{
    projectId: string;
    complianceId: string;
  }>();

  const [formState, setFormState] = useState<Record<string, any>>({
    ...initialData,
    scheduleStarts: isUpdateForm ? dayjs(initialData.scheduleStarts) : null,
    scheduleEnds: isUpdateForm ? dayjs(initialData.scheduleEnds) : null,
  });

  const [showCalenderView, toggleCalenderView] = useState(false);

  const [getFolders, { data: folders, loading: loadingFolders }] = useLazyQuery<
    GetFoldersQueryResponse,
    GetFoldersQueryVariables
  >(GET_FOLDERS_QUERY_FOR_LV, {
    variables: {
      type: FolderTypeEnum.PROJECT,
      projectOrLeadId: projectId,
      folderName: FolderNameEnum.COMPLIANCE,
    },
  });

  const [getUsers, { loading: loadingUsers, data: users }] = useLazyQuery<
    UserNamesQueryResponse,
    UserNamesQueryVariables
  >(USERS_NAMES_QUERY);

  const [createComplianceCheckList, { loading: loadingComplianceCheckList }] = useMutation<
    createComplianceChecklistMutationResponse,
    createComplianceChecklistMutationVariables
  >(CREATE_COMPLIANCE_CHECKLIST);

  const [updateComplianceChecklist, { loading: updatingComplianceChecklist }] = useMutation<
    UpdateComplianceChecklistResponse,
    UpdateComplianceChecklistVariables
  >(UPDATE_COMPLIANCE_CHECKLIST_MUTATION);

  const navigate = useNavigate();

  const handleChange = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleSubmit = () => {
    const { scheduleStarts, scheduleEnds, ...formData } = formState;
    const refinedData = removeEmptyFields(formData);

    const requestPayload = {
      project: projectId,
      assignTo: refinedData.assignTo.map(user => user._id),
      scheduleStarts: formatDate(scheduleStarts),
      scheduleEnds:
        refinedData.frequency === FrequencyEnum['ONE TIME']
          ? formatDate(scheduleStarts)
          : formatDate(scheduleEnds),
      docName: refinedData.docName,
      docType: refinedData.docType,
      frequency: refinedData.frequency,
      status: refinedData.status,
    };

    if (!!initialData._id) {
      updateComplianceChecklist({
        variables: {
          input: { _id: complianceId, ...requestPayload },
        },
        onCompleted: _ => navigate(-1),
      });
    } else
      createComplianceCheckList({
        variables: {
          input: [requestPayload],
        },
        onCompleted: res => {
          navigate(`/compliances/${projectId}/${res.createComplianceChecklist[0]._id}`, {
            replace: true,
          });
        },
      });
  };

  const formattedCustomDate = useMemo(() => {
    let startLabel = '',
      endLabel = '';

    if (!!!formState.scheduleStarts) {
      startLabel = '-';
    } else {
      startLabel = dayjs.isDayjs(formState.scheduleStarts)
        ? formState.scheduleStarts.format('DD/MM/YYYY')
        : dayjs(formState.scheduleStarts).format('DD/MM/YYYY');
    }
    if (!!!formState.scheduleEnds) {
      endLabel = '-';
    } else {
      endLabel = dayjs.isDayjs(formState.scheduleEnds)
        ? formState.scheduleEnds.format('DD/MM/YYYY')
        : dayjs(formState.scheduleEnds).format('DD/MM/YYYY');
    }

    return startLabel + ' - ' + endLabel;
  }, [formState.scheduleStarts, formState.scheduleEnds]);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container columnSpacing={2} rowGap={2} md={6}>
        <Grid item xs={12}>
          <TextField
            label="Doc Name"
            value={formState.docName ?? ''}
            onChange={e => handleChange('docName', e.target.value)}
            fullWidth
            required
          />
        </Grid>
        {!isUpdateForm && (
          <Grid item xs={6}>
            <AutocompleteWithFetch
              label="Folder"
              options={folders?.getFolders?.folders ?? []}
              handleChange={val => handleChange('docType', val)}
              value={formState.docType}
              loading={loadingFolders}
              fetch={getFolders}
              required
            />
          </Grid>
        )}
        {isUpdateForm && (
          <Grid item xs={6}>
            <Select
              label="Status"
              options={Object.values(ComplianceChecklistStatus).map(o => ({ label: o, value: o }))}
              value={formState.status}
              onChange={val => handleChange('status', val)}
              required
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <Select
            label="Frequency"
            required
            disabled={isUpdateForm}
            value={formState.frequency ?? ''}
            options={Object.keys(FrequencyEnum).map(k => ({ label: k, value: FrequencyEnum[k] }))}
            onChange={val => {
              handleChange('frequency', val);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          {formState.frequency !== FrequencyEnum['ONE TIME'] ? (
            <Fieldset label="Date Range *" variant="small">
              <Box
                display="flex"
                alignItems="center"
                columnGap={0.5}
                onClick={() => toggleCalenderView(true)}
              >
                <IconButton size="small">
                  <CalendarMonthIcon />
                </IconButton>
                <Typography fontWeight={500} variant="caption" sx={{ cursor: 'pointer' }}>
                  {formattedCustomDate}
                </Typography>
              </Box>
            </Fieldset>
          ) : (
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  format="DD/MM/YYYY"
                  value={
                    dayjs.isDayjs(formState.scheduleStarts)
                      ? formState.scheduleStarts
                      : dayjs(formState.scheduleStarts)
                  }
                  onChange={val => {
                    handleChange('scheduleStarts', val);
                    handleChange('scheduleEnds', val);
                  }}
                  slotProps={{
                    textField: {
                      required: true,
                    },
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={12}>
          <MultiSelectAutocomplete
            values={
              !!formState.assignTo && !!formState.assignTo.length
                ? !!formState.assignTo[0].firstName
                  ? transformCustomerNames(formState.assignTo)
                  : formState.assignTo
                : []
            }
            fetch={getUsers}
            handleChange={val => handleChange('assignTo', val)}
            label="Assign To"
            loading={loadingUsers}
            options={
              users?.getUsers?.map(usr => ({
                _id: usr._id,
                name: usr.firstName + ' ' + (usr.lastName ?? ''),
                referenceId: usr.empId,
              })) ?? []
            }
            variables={{
              filter: {
                department: [DepartmentEnum.COMPLIANCE],
              },
            }}
            required={!!!formState.assignTo?.length}
          />
        </Grid>
        <SimplePopup
          onClose={() => toggleCalenderView(false)}
          open={showCalenderView}
          enableBackdropClickClosure
        >
          <DateRangeCalender
            initialDateRange={[formState.scheduleStarts, formState.scheduleEnds]}
            onSubmit={dates => {
              toggleCalenderView(false);
              handleChange('scheduleStarts', dates[0]);
              handleChange('scheduleEnds', dates[1]);
            }}
          />
        </SimplePopup>

        <Grid item xs={12} textAlign={'end'}>
          <LoadingButton
            variant="contained"
            size="medium"
            type="submit"
            disabled={!!!formState.scheduleStarts || !!!formState.scheduleEnds}
            loading={loadingComplianceCheckList || updatingComplianceChecklist}
          >
            {isUpdateForm ? 'UPDATE' : 'CREATE'}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
