import { DashboardContextProvider } from 'contexts/dashboardContext';
import { UserContextProvider } from 'contexts/userContext';
import { Suspense } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import LoadingIndicator from 'components/LoadingIndicator';
import ProtectedRoutes from 'components/ProtectedRoutes';

import ForgotPassword from './Auth/ForgotPassword';
import Login from './Auth/Login';
import ResetPassword from './Auth/ResetPassword';
import RouteNotFound from './Auth/RouteNotFound';
import CompaniesPage from './Companies';
import BranchDetailsPage from './Companies/BranchDetails';
import CompanyDetailsPage from './Companies/CompanyDetails';
import CreateCompanyPage from './Companies/CreateCompany';
import ComplianceProjectsPage from './Compliances';
import ComplianceChecklistDetailsPage from './Compliances/CompliancesChecklistDetails';
import UpdateCompliancesDetails from './Compliances/CompliancesChecklistDetails/UpdateComplianceForm';
import CompliancesListPage from './Compliances/CompliancesListPage';
import CreateComplianceCheckList from './Compliances/CreateComplianceCheckList';
import Home from './Dashboard';
import DashboardLeads from './Dashboard/LeadDashboard/DashboardLeads';
import DocumentMasterPage from './DocumentsMaster';
import CopyStaticFilesPage from './DocumentsMaster/CopyStaticFiles';
import CreateDocumentPage from './DocumentsMaster/CreateDocument';
import DocumentFoldersPage from './DocumentsMaster/DocumentFolders';
import DocumentsMasterFilesPage from './DocumentsMaster/DocumentMasterFiles';
import DocumentSubFoldersPage from './DocumentsMaster/DocumentSubfolders';
import DocumentsFileDetailsPage from './DocumentsMaster/DocumentsFileDetails';
import StaticFileDetailsPage from './DocumentsMaster/FileDetails';
import StaticFilesPage from './DocumentsMaster/StaticFiles';
import StaticFoldersPage from './DocumentsMaster/StaticFolders';
import StaticSubFoldersPage from './DocumentsMaster/StaticSubFolders';
// import Dashboard from './Dashboard';
import FollowupsPage from './Followups';
import CreateFollowup from './Followups/CreateFollowup';
import FollowupDetailsPage from './Followups/FollowupDetails';
import LeadsPage from './Leads';
import CreateLead from './Leads/CreateLead';
import LeadAssociateDetails from './Leads/LeadAssociates';
import LeadDetailsPage from './Leads/LeadDetails';
import PQsPage from './PQs';
import ExternalUserFilesPage from './PQs/ExternalUserFiles';
import PQDetails from './PQs/PQDetails';
import ProjectPage from './Projects';
import CreateProject from './Projects/CreateProject';
import RFQPage from './RFQs';
import BOQDetailsPage from './RFQs/BOQDetails';
import BulkUpdateBOQsPage from './RFQs/BulkUpdateBOQs';
import ManageBOQ from './RFQs/ManageBOQ';
import ManageClientValidation from './RFQs/ManageClientVaildation';
import MapBOQRAPage from './RFQs/MapBOQRA';
import RFQDetailsPage from './RFQs/RFQDetails';
import RateAnalysisPage from './RateAnalysisTemplates';
import ConversionDetailsPage from './RateAnalysisTemplates/ConversionDetails';
import ConversionTablePage from './RateAnalysisTemplates/ConversionTable';
import CreateConversions from './RateAnalysisTemplates/CreateConversions';
import ManageRateAnalysisTemplatePage from './RateAnalysisTemplates/ManageRateAnalysisTemplate';
import RATemplateDetailsPage from './RateAnalysisTemplates/RADetails';
import RateAnalysisTemplatePage from './RateAnalysisTemplates/RATemplatePage';

// import ProjectTemplate from './Projects';
// import CreateProject from './Projects/CreateProject';
// import RFPPage from './RFPs';
// import CreateRFPPage from './RFPs/CreateRFP';
// import RFPDetailsPage from './RFPs/RFPDetails';

export default function App() {
  return (
    <Router>
      <Suspense fallback={<LoadingIndicator />}>
        <UserContextProvider>
          <DashboardContextProvider>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route element={<ProtectedRoutes />}>
                <Route path="/" element={<Home />} />
                <Route path="/dashboard-leads" element={<DashboardLeads />} />

                <Route path="/companies" element={<CompaniesPage />} />
                <Route path="/companies/create-company" element={<CreateCompanyPage />} />
                <Route path="/companies/:companyId" element={<CompanyDetailsPage />} />
                <Route
                  path="/companies/:companyId/branches/:branchId"
                  element={<BranchDetailsPage />}
                />
                <Route path="/leads" element={<LeadsPage />} />
                <Route path="/leads/create-lead" element={<CreateLead />} />
                <Route path="/leads/:leadId" element={<LeadDetailsPage />} />
                <Route path="/leads/:leadId/:companyType" element={<LeadAssociateDetails />} />
                <Route
                  path="/leads/:leadId/:companyType/:companyId"
                  element={<LeadAssociateDetails />}
                />

                <Route path="/pqs" element={<PQsPage />} />
                <Route path="/pqs/:leadId" element={<PQDetails />} />
                <Route path="/external-user" element={<ExternalUserFilesPage />} />

                {/* <Route path="/rfps" element={<RFPPage />} />
                <Route path="/rfps/create-rfp" element={<CreateRFPPage />} />
                <Route path="/rfps/:rfpId" element={<RFPDetailsPage />} /> */}

                <Route path="followups" element={<FollowupsPage />} />
                <Route path="followups/create-followup" element={<CreateFollowup />} />
                <Route path="followups/:followupId" element={<FollowupDetailsPage />} />

                <Route path="/documents" element={<DocumentMasterPage />} />
                <Route path="/documents/create-document" element={<CreateDocumentPage />} />

                <Route path="/documents/STATIC" element={<StaticFoldersPage />} />
                <Route path="/documents/STATIC/:folderId" element={<StaticSubFoldersPage />} />
                <Route
                  path="/documents/STATIC/:folderId/:subFolderId"
                  element={<StaticFilesPage />}
                />
                <Route
                  path="/documents/STATIC/:folderId/:subFolderId/:fileId"
                  element={<StaticFileDetailsPage />}
                />
                <Route
                  path="/documents/:folderType/:documentId"
                  element={<DocumentFoldersPage />}
                />
                <Route
                  path="/documents/:folderType/:documentId/:folderId"
                  element={<DocumentSubFoldersPage />}
                />
                <Route
                  path="/documents/:folderType/:documentId/:folderId/:subFolderId"
                  element={<DocumentsMasterFilesPage />}
                />
                <Route
                  path="/documents/:folderType/:documentId/:folderId/:subFolderId/add-files"
                  element={<CopyStaticFilesPage />}
                />
                <Route
                  path="/documents/:folderType/:documentId/:folderId/:subFolderId/:fileId"
                  element={<DocumentsFileDetailsPage />}
                />

                <Route path="/compliances" element={<ComplianceProjectsPage />} />
                <Route path="/compliances/:projectId" element={<CompliancesListPage />} />
                <Route
                  path="/compliances/:projectId/create"
                  element={<CreateComplianceCheckList />}
                />
                <Route
                  path="/compliances/:projectId/:complianceId"
                  element={<ComplianceChecklistDetailsPage />}
                />
                <Route
                  path="/compliances/:projectId/:complianceId/edit"
                  element={<UpdateCompliancesDetails />}
                />

                <Route path="/projects" element={<ProjectPage />} />
                <Route path="/projects/create-project" element={<CreateProject />} />

                <Route path="/rfqs" element={<RFQPage />} />
                <Route path="/rfqs/:rfqId" element={<RFQDetailsPage />} />
                <Route path="/rfqs/:rfqId/bulk-update" element={<BulkUpdateBOQsPage />} />
                <Route path="/rfqs/:rfqId/client-validation" element={<ManageClientValidation />} />
                <Route path="/rfqs/:rfqId/:boqId" element={<BOQDetailsPage />} />
                <Route path="/rfqs/:rfqId/:boqId/map-boq-ra" element={<MapBOQRAPage />} />
                <Route path="/rfqs/manage-boq" element={<ManageBOQ />} />

                <Route path="/rateanalysis" element={<RateAnalysisPage />} />
                <Route path="/rateanalysis/templates" element={<RateAnalysisTemplatePage />} />
                <Route path="/rateanalysis/conversion-table" element={<ConversionTablePage />} />
                <Route
                  path="/rateanalysis/conversion-table/:conversionId"
                  element={<ConversionDetailsPage />}
                />
                <Route path="/rateanalysis/create-conversion" element={<CreateConversions />} />
                <Route
                  path="/rateanalysis/templates/:rateAnalysisId"
                  element={<RATemplateDetailsPage />}
                />
                <Route
                  path="/rateanalysis/manage-ra"
                  element={<ManageRateAnalysisTemplatePage />}
                />

                <Route path="/settings" element={<ResetPassword />} />
              </Route>
              <Route path="*" element={<RouteNotFound />} />
            </Routes>
          </DashboardContextProvider>
        </UserContextProvider>
      </Suspense>
    </Router>
  );
}
