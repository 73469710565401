import AddIcon from '@mui/icons-material/Add';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SyncIcon from '@mui/icons-material/Sync';
import { Grid, Tooltip } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LeadStatusEnum } from 'types';

import Section from 'components/Section';
import DataGridTable from 'components/Table';

import { extractLastSegment } from 'utils/formatHelper';

const RAMappingSection: React.FC<{
  rfqId: string;
  rateAnalysisId: string;
  rfqStatus: string;
  isEdit: boolean;
  items: any[];
}> = ({ rfqId, items, rateAnalysisId, rfqStatus, isEdit }) => {
  const navigate = useNavigate();
  const boqId = extractLastSegment(window.location.href);

  return (
    <Grid container direction="column" rowGap={1.5} mt={0}>
      <Section
        title="Item List"
        collapsible
        action={
          rfqStatus === LeadStatusEnum['RFP RECEIVED'] && (
            <>
              <Tooltip title="Sync cost with ERP">
                <SyncIcon fontSize="small" onClick={() => navigate(`/rfqs/${rfqId}/${boqId}`)} />
              </Tooltip>
              {isEdit ? (
                <Tooltip title="Update Rate Analysis">
                  <EditNoteIcon
                    fontSize="small"
                    onClick={() =>
                      navigate(
                        `/rfqs/${rfqId}/${boqId}/map-boq-ra?type=update&rateAnalysisId=${rateAnalysisId}&rfqStatus=${rfqStatus}`
                      )
                    }
                    sx={{ cursor: 'pointer' }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Create Rate Analysis">
                  <AddIcon
                    fontSize="small"
                    onClick={() =>
                      navigate(
                        `/rfqs/${rfqId}/${boqId}/map-boq-ra?type=create&rfqStatus=${rfqStatus}`
                      )
                    }
                    sx={{ cursor: 'pointer' }}
                  />
                </Tooltip>
              )}
            </>
          )
        }
      >
        <DataGridTable
          sx={{
            maxWidth: '80vw',
            minHeight: '30vh',
            mx: 'auto',
            mt: 0.5,
          }}
          columns={[
            {
              field: 'item',
              headerName: 'Item Id',
              minWidth: 150,
              disableColumnMenu: true,
              disableReorder: true,
            },
            {
              field: 'name',
              headerName: 'Name',
              minWidth: 250,
              disableColumnMenu: true,
              disableReorder: true,
            },
            {
              field: 'uom',
              headerName: 'UOM',
              minWidth: 150,
              disableColumnMenu: true,
              disableReorder: true,
              sortable: false,
            },
            {
              field: 'qty',
              headerName: 'Quantity',
              minWidth: 150,
              disableColumnMenu: true,
              disableReorder: true,
              sortable: false,
            },
            {
              field: 'actualCost',
              headerName: 'ERP Cost',
              minWidth: 165,
              disableColumnMenu: true,
              disableReorder: true,
              sortable: false,
            },
            {
              field: 'modifiedCost',
              headerName: 'Modified Cost',
              minWidth: 165,
              disableColumnMenu: true,
              disableReorder: true,
              sortable: false,
            },
            {
              field: 'totalCost',
              headerName: 'Amount',
              disableColumnMenu: true,
              disableReorder: true,
              sortable: false,
              minWidth: 165,
              valueFormatter: (value: number) => value.toFixed(2),
            },
          ]}
          getRowId={row => row.item}
          localeText={{ noRowsLabel: 'There are no items' }}
          rows={items}
          hideFooterPagination
        />
      </Section>
    </Grid>
  );
};

export default RAMappingSection;
