import { gql } from '@apollo/client';

export const UPDATE_RFQ_VALIDATION_MUTATION = gql`
  mutation UpdateRFQValidation($input: UpdateRFQValidationInput!) {
    updateRFQValidation(input: $input) {
      _id
      lead {
        name
        _id
      }
      totalAreaForWork
      uom
      approxWorkValue
      executionTime
      advance
      onSupply
      ra
      handoverPercentage
      dlpYears
      dlpRetentionPercentage
      totalHandoverPercentage
      timeToDesign
      designRequirement
      designInvestmentRequired
      emd
      abg
      pbg
      otherBG
      paymentTermsNegotiable
      comments
      reasonForNogo
    }
  }
`;

export const CREATE_RFQ_VALIDATION_MUTATION = gql`
  mutation CreateRFQValidation($input: CreateRFQValidationInput!) {
    createRFQValidation(input: $input) {
      _id
      referenceId
      lead {
        name
        referenceId
        _id
      }
      totalAreaForWork
      uom
      approxWorkValue
      executionTime
      advance
      onSupply
      ra
      handoverPercentage
      dlpYears
      dlpRetentionPercentage
      totalHandoverPercentage
      timeToDesign
      designRequirement
      designInvestmentRequired
      emd
      abg
      pbg
      otherBG
      paymentTermsNegotiable
      comments
      reasonForNogo
    }
  }
`;
