import { gql } from '@apollo/client';

export const CREATE_RATE_ANALYSIS_MUTATION = gql`
  mutation CreateRateAnalysis($input: CreateRateAnalysisInput!) {
    createRateAnalysis(input: $input) {
      _id
      # boq
      # referenceId
      # totalAmount
      # items {
      #   # item
      #   name
      #   qty
      #   uom
      #   actualPrice
      #   modifiedPrice
      #   amount
      # }
    }
  }
`;

export const UPDATE_RATE_ANALYSIS_MUTATION = gql`
  mutation UpdateRateAnalysis($input: UpdateRateAnalysisInput!) {
    updateRateAnalysis(input: $input) {
      totalCost
      totalPrice
      boq {
        boqId
        name
        description
        uom
        qty
        cost
        margin
        discount
        wastage
        price
        _id
        referenceId
      }
      referenceId
      _id
      items {
        item
        name
        qty
        uom
        actualCost
        modifiedCost
        totalCost
      }
    }
  }
`;
