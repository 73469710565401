import { gql, useMutation } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UOMEnum } from 'types';

import { ConfirmationPopup } from 'components/Popup';
import Section from 'components/Section';

import { getEnumKeyFromValue } from 'utils/transformFn';

import UpdateConversionForm from './UpdateConversionForm';

const DELETE_UOM_CONVERSION_MUTATION = gql`
  mutation DeleteUomConversion($id: ID!) {
    deleteUomConversion(_id: $id)
  }
`;

const ConversionSection: React.FC<{ conversion: any }> = ({ conversion }) => {
  const [enableFormEdit, toggleFormEdit] = useState(false);
  const [toggleConfirmationPopup, setToggleConfirmationPopup] = useState(false);

  const [deleteUomConversion, { loading: deletingRA }] = useMutation(
    DELETE_UOM_CONVERSION_MUTATION
  );

  const navigate = useNavigate();

  return (
    <Grid container direction="column" rowGap={1.5}>
      <Section
        title="Conversion Details"
        data={[
          {
            label: 'Reference Id',
            value: conversion.referenceId,
            type: 'STRING',
          },
          {
            label: 'BOQ UOM',
            value: getEnumKeyFromValue(UOMEnum, conversion.boqUom),
            type: 'STRING',
          },
          {
            label: 'Rate Analysis UOM',
            value: getEnumKeyFromValue(UOMEnum, conversion.raUom),
            type: 'STRING',
          },
          {
            label: 'Conversion Factor',
            value: conversion.conversionFactor,
            type: 'STRING',
          },
        ]}
        action={
          <>
            <DeleteIcon
              onClick={() => setToggleConfirmationPopup(true)}
              sx={{ cursor: 'pointer', mr: 1 }}
              fontSize="small"
            />
            <EditNoteIcon
              onClick={() => toggleFormEdit(true)}
              sx={{ cursor: 'pointer' }}
              fontSize="small"
            />
          </>
        }
      />
      <UpdateConversionForm
        openForm={enableFormEdit}
        toggleForm={toggleFormEdit}
        conversion={conversion}
      />

      <ConfirmationPopup
        onClose={() => {
          setToggleConfirmationPopup(false);
        }}
        onConfirmation={() => {
          deleteUomConversion({
            variables: { id: conversion._id },
            onCompleted: () => {
              setToggleConfirmationPopup(false);
              navigate('/rateanalysis/conversion-table');
            },
          });
        }}
        open={toggleConfirmationPopup}
        title="Do you want to delete this conversion ?"
        confirmationLabel="Yes"
        loading={deletingRA}
      />
    </Grid>
  );
};

export default ConversionSection;
