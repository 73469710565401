import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, InputAdornment, Typography } from '@mui/material';
import { GridRowModel } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import {
  CREATE_RATE_ANALYSIS_MUTATION,
  UPDATE_RATE_ANALYSIS_MUTATION,
} from 'graphql/mutation/rateAnalysis';
import { GET_BOQ_BYID_QUERY } from 'graphql/query/boq';
import { GET_ITEM_MASTER_QUERY } from 'graphql/query/itemMaster';
import {
  GET_ITEM_MASTER_BY_RA_TEMPLATE_AND_BOQ,
  GET_RA_TEMPLATE_QUERY_WITHOUT_FIELDS,
} from 'graphql/query/rateAnalysisTemplate';
import { usePreviousPersistent } from 'hooks/usePreviousPersistent';
import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { UOMEnum } from 'types';

import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';
import TextField, { NumberInputField } from 'components/Inputs/TextField';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import { ConfirmationPopup } from 'components/Popup';
import DataGridTable from 'components/Table';

import { getEnumKeyFromValue } from 'utils/transformFn';

const MapBOQRAPage = () => {
  const navigate = useNavigate();
  const queryString = useLocation()?.search;
  const params = new URLSearchParams(queryString);
  const screenType = params.get('type');
  const rfqStatus = params.get('rfqStatus');
  const rateAnalysisId = params.get('rateAnalysisId');

  const screenTitle =
    screenType === 'create' ? 'Create Rate Analysis for' : 'Update Rate Analysis for';

  const { boqId = '', rfqId } = useParams<{ boqId: string; rfqId: string }>();

  const [formState, setFormState] = useState<Record<string, any>>({});
  const { data, loading: loadingBOQ } = useQuery<any>(GET_BOQ_BYID_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      id: boqId,
    },
  });

  const [getRateAnalysisTemplates, { loading: loadingRaTemplates, data: raTemplatesData }] =
    useLazyQuery(GET_RA_TEMPLATE_QUERY_WITHOUT_FIELDS, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      variables: {
        page: 1,
        limit: 100,
      },
    });

  const raTemplateDetails = raTemplatesData?.getRateAnalysisTemplates?.rateAnalysisTemplates;
  const raDetails = data?.getBOQById?.rateAnalysis;
  const boqDetails = data?.getBOQById;

  const templateId = raDetails?.raTemplateDetails?._id;
  const templateName =
    raDetails?.raTemplateDetails?.name +
    ' (' +
    getEnumKeyFromValue(UOMEnum, raDetails?.raTemplateDetails?.baseUom) +
    ')';

  const { loading: loadingItemMasterByRaTemplates, data: itemMasterByRaTemplates } = useQuery(
    GET_ITEM_MASTER_BY_RA_TEMPLATE_AND_BOQ,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      skip: !formState?.template?._id,
      variables: {
        raTemplatedId: formState?.template?._id,
        boqId,
      },
    }
  );

  const itemMastersByRaTemplateAndBoqDetails =
    itemMasterByRaTemplates?.getItemMastersByRaTemplateAndBoq;

  const [getItemMaster, { loading: loadingItemMaster, data: ItemMaster }] =
    useLazyQuery(GET_ITEM_MASTER_QUERY);

  // Map description to name
  const itemMasterOptions = useMemo(() => {
    if (ItemMaster?.getItemMasters) {
      return ItemMaster.getItemMasters.map(item => ({
        ...item,
        name: item?.description,
      }));
    }
  }, [ItemMaster]);

  const [createRateAnalysis, { loading: creatingRA }] = useMutation(CREATE_RATE_ANALYSIS_MUTATION);

  const [updateRateAnalysis, { loading: updatingRA }] = useMutation(UPDATE_RATE_ANALYSIS_MUTATION);

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isRevertPopupVisible, setIsRevertPopupVisible] = useState(false);

  const [pendingChange, setPendingChange] = useState<{ fieldName: string; value: any } | null>(
    null
  );
  const prevsData = usePreviousPersistent(pendingChange);

  const handleClosePopup = () => {
    setIsPopupVisible(false);
    window.location.reload();
  };

  const handleChange = (fieldName: string, value: any) => {
    if (screenType === 'update') {
      setIsPopupVisible(true);
      setPendingChange({ fieldName, value });
    } else {
      setFormState(prev => ({
        ...prev,
        [fieldName]: value,
      }));
    }
  };

  const handleConfirmation = () => {
    // Apply the pending change after confirmation
    if (pendingChange) {
      setFormState(prev => ({
        ...prev,
        [pendingChange.fieldName]: pendingChange.value,
      }));
      setPendingChange(null);
    }

    setIsPopupVisible(false);
  };

  const [rows, setRows] = useState([
    {
      _id: Math.random() * dayjs().unix(),
      referenceId: '',
      resp: { _id: '', name: '', cost: 0 },
      qty: 0,
      uom: '',
      name: '',
      item: '',
      amount: 0,
      modifiedCost: 0,
    },
  ]);

  const [formTableState, setTableFormState] = useState(() =>
    rows.reduce((prev, curr) => {
      prev[curr._id] = curr;
      return prev;
    }, {})
  );

  useEffect(() => {
    if (!itemMastersByRaTemplateAndBoqDetails) {
      // If the data is null or an error occurred, clear the states
      setRows([]);
      setTableFormState({});
      return;
    }

    try {
      // If data exists, map it to the required format
      const updatedRows = itemMastersByRaTemplateAndBoqDetails.map(item => {
        const { referenceId, cost } = item.item;
        const { qty, uom, name } = item;

        return {
          referenceId,
          qty,
          uom,
          name,

          // For API purpose only
          _id: Math.random() * dayjs().unix(),
          resp: {
            referenceId,
            name,
            cost,
            uom,
          },
        };
      });

      // Update rows state
      setRows(updatedRows);

      // Update formTableState
      const updatedFormTableState = updatedRows.reduce((prev, curr) => {
        prev[curr._id] = curr;
        return prev;
      }, {});
      setTableFormState(updatedFormTableState);
    } catch (error) {
      setRows([]);
      setTableFormState({});
    }
  }, [itemMastersByRaTemplateAndBoqDetails, formState?.template]);

  // update functionality
  useEffect(() => {
    if (raDetails && screenType === 'update') {
      const { name, description, baseQty, baseUom, items } = raDetails ?? {};

      setFormState({
        name,
        description,
        baseQty,
        baseUom,
      });

      // Map API items to the rows structure
      const updatedRows = items?.map(item => {
        return {
          _id: Math.random() * dayjs().unix(),
          referenceId: item?.item,
          resp: {
            referenceId: item?.item,
            _id: item?.item,
            name: item.name,
            cost: item?.actualCost,
            uom: item?.uom,
          },
          modifiedCost: item?.modifiedCost,
          qty: item?.qty,
          uom: item?.uom,
          name: item.name,
          item: item?.item,
          amount: item.qty * item.price,
        };
      });

      // Update rows state
      setRows(updatedRows);

      // Optionally update formTableState
      const updatedFormTableState = updatedRows?.reduce((prev, curr) => {
        prev[curr._id] = curr;
        return prev;
      }, {});
      setTableFormState(updatedFormTableState);
    }
  }, [raDetails]);

  const filteredItemMasterOptions = useMemo(
    () =>
      itemMasterOptions?.filter(
        (item: any) => !rows?.some((row: any) => row?.resp?.referenceId === item?.referenceId)
      ),
    [rows, itemMasterOptions]
  );

  const handleTableChange = (rowId: string, fieldName: string, value: any) => {
    setTableFormState(prev => {
      const updatedRow = {
        ...(prev[rowId] ?? {}),
        [fieldName]: value,
      };

      // Dynamically update `uom` if `name` is being changed
      if (fieldName === 'resp') {
        updatedRow.uom = value.uom;
        updatedRow.referenceId = value.referenceId;
        updatedRow.name = value.name;
        updatedRow.item = value._id;
        updatedRow.modifiedCost = value.cost;
      }

      const updatedState = {
        ...prev,
        [rowId]: updatedRow,
      };

      // Synchronize rows with formTableState
      setRows(Object.values(updatedState));
      return updatedState;
    });
  };

  const handleAddRow = () => {
    const newRow = {
      _id: Math.random() * dayjs().unix(),
      referenceId: '',
      resp: { _id: '', name: '', cost: 0 },
      qty: 0,
      uom: '',
      name: '',
      item: '',
      amount: 0,
      modifiedCost: 0,
    };

    setRows(prevRows => [...prevRows, newRow]);
    setTableFormState(prev => ({
      ...prev,
      [newRow._id]: newRow,
    }));
  };

  const handleDeleteRow = (id: number) => {
    // Update formTableState by removing the specific row
    setTableFormState(prev => {
      const updatedState = { ...prev };
      delete updatedState[id];
      return updatedState;
    });

    // Update rows by filtering out the deleted row
    setRows(prevRows => prevRows.filter(row => row._id !== id));
  };

  const handleProcessRowUpdate = (newRow: GridRowModel) => {
    setTableFormState(prevState => ({
      ...prevState,
      [newRow._id]: {
        ...(prevState[newRow._id] ?? {}), // Preserve existing fields
        ...newRow, // Merge updated row fields
      },
    }));

    // Optionally, update rows (derived from formTableState if necessary)
    setRows(
      Object.values({
        ...formTableState,
        [newRow._id]: {
          ...(formTableState[newRow._id] ?? {}),
          ...newRow,
        },
      })
    );

    return newRow; // Return the updated row back to DataGrid
  };

  const tableData =
    formTableState &&
    Object?.values(formTableState)?.map((item: any) => {
      const modifiedCost =
        item?.modifiedCost === undefined ? Number(item?.resp?.cost) : Number(item?.modifiedCost);
      return {
        // referenceId: item?.resp?.referenceId,
        // amount: Number(item?.qty * modifiedCost),
        uom: item?.uom,
        item: item?.resp?.referenceId,
        name: item?.name,
        qty: Number(item?.qty),
        actualCost: Number(item?.resp?.cost),
        modifiedCost,
      };
    });

  const tableDataAggregate =
    formTableState &&
    Object?.values(formTableState)?.map((item: any) => {
      const modifiedCost =
        item?.modifiedCost === undefined ? Number(item?.resp?.cost) : Number(item?.modifiedCost);
      return {
        // Down aggregate calculations,
        actualAmount: Number(item?.qty * item?.resp?.cost),
        modifiedAmount: Number(item?.qty * modifiedCost),
      };
    });

  const totalPriceFromTemplate = tableDataAggregate?.reduce(
    (acc, curr) => acc + (curr?.actualAmount || 0),
    0
  );
  const totalPriceAfterModification = tableDataAggregate?.reduce(
    (acc, curr) => acc + (curr?.modifiedAmount || 0),
    0
  );

  const handleSubmit = () => {
    if (screenType === 'update') {
      updateRateAnalysis({
        variables: {
          input: {
            _id: rateAnalysisId,
            items: tableData,
            rateAnalysisTemplate: templateId ?? formState?.template?._id,
            boq: boqId,
          },
        },
        onCompleted: res =>
          navigate(`/rfqs/${rfqId}/${boqId}?rfqStatus=${rfqStatus}`, { replace: true }),
      });
    } else {
      createRateAnalysis({
        variables: {
          input: {
            boq: boqId,
            items: tableData,
            rateAnalysisTemplate: formState?.template?._id,
            // totalAmount: totalPriceAfterModification,
          },
        },
        onCompleted: res =>
          navigate(`/rfqs/${rfqId}/${boqId}?rfqStatus=${rfqStatus}`, { replace: true }),
      });
    }
  };

  const trasOptions = raTemplateDetails?.map((item: any) => ({
    ...item,
    name: item?.name + ' ( ' + getEnumKeyFromValue(UOMEnum, item?.baseUom) + ' )',
  }));

  return (
    <Navbar
      goBackButtonConfig={{
        title: `${screenTitle} ${boqDetails?.referenceId}`,
      }}
    >
      {loadingBOQ || loadingItemMasterByRaTemplates ? (
        <Grid item container direction="column" xs={10} mt={0.5} p={2}>
          <LoadingIndicator />
        </Grid>
      ) : (
        <>
          <Grid item container direction="column" xs={10} mt={0.5} p={2}>
            <form
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
              onSubmit={e => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <Grid container item xs={10} columnSpacing={2} rowSpacing={2}>
                <Grid item xs={2}>
                  <TextField
                    disabled
                    value={boqDetails?.boqId ?? ''}
                    onChange={e => handleChange('boqID', e.target.value)}
                    label="BOQ ID"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    value={boqDetails?.name ?? ''}
                    onChange={e => handleChange('boqName', e.target.value)}
                    label="BOQ Name"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    value={getEnumKeyFromValue(UOMEnum, boqDetails?.uom) ?? ''}
                    onChange={e => handleChange('boqName', e.target.value)}
                    label="UOM"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField disabled value={boqDetails?.qty ?? 0} label="Quantity" />
                </Grid>

                <Grid item xs={12}>
                  <AutocompleteWithFetch
                    fetch={getRateAnalysisTemplates}
                    handleChange={val => handleChange('template', val)}
                    label="Choose rate analysis templates"
                    loading={loadingRaTemplates}
                    value={formState?.template}
                    // options={raTemplateDetails}
                    options={trasOptions}
                    variant="standard"
                    size="small"
                  />
                </Grid>
              </Grid>

              <Grid container item xs={10} columnSpacing={2} rowSpacing={2}>
                <Grid container item xs={12} mt={2} columnSpacing={2} rowSpacing={2}>
                  {screenType === 'update' && (
                    <Grid item xs={4}>
                      <TextField
                        disabled
                        value={templateName ?? ''}
                        label="Template Name"
                        helperText={
                          templateName && (
                            <Typography variant="caption" fontWeight="bold">
                              See Template details
                              <Link
                                to={`/rateanalysis/templates/${templateId}?layout=noNavbar`}
                                style={{
                                  textDecoration: 'none',
                                  marginLeft: '5px',
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                here
                              </Link>
                            </Typography>
                          )
                        }
                      />
                    </Grid>
                  )}
                  <Grid item xs={screenType === 'create' ? 6 : 4}>
                    <TextField
                      disabled
                      value={totalPriceFromTemplate?.toFixed(2) ?? ''}
                      label="Total ERP Cost"
                      helperText="Aggregated sum of all line items of ERP Cost * Quantity."
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <b color="lightGray">₹</b>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={screenType === 'create' ? 6 : 4}>
                    <TextField
                      disabled
                      value={totalPriceAfterModification?.toFixed(2) ?? ''}
                      label="Total Cost"
                      helperText="Aggregated sum of all line items of Cost * Amount."
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <b color="lightGray">₹</b>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container item xs={12} columnSpacing={2} rowSpacing={2}>
                  <Grid item xs={12}>
                    <DataGridTable
                      sx={{
                        maxWidth: '90vw',
                        height: '450px',
                        minHeight: '400px',
                        mx: 'auto',
                        mt: 0.5,
                      }}
                      columns={[
                        {
                          field: 'referenceId',
                          headerName: 'Item Id',
                          minWidth: 150,
                          editable: true,
                          disableColumnMenu: true,
                          disableReorder: true,
                          renderCell: params => (
                            <Box
                              sx={{
                                height: 50,
                                my: 'auto',
                                pt: 2,
                              }}
                            >
                              <TextField
                                disabled
                                value={formTableState[params?.row?._id]?.resp.referenceId ?? ''}
                                variant="standard"
                                size="small"
                              />
                            </Box>
                          ),
                        },
                        {
                          field: 'resp',
                          headerName: 'Item',
                          minWidth: 250,
                          renderCell: params => (
                            <Box
                              sx={{
                                height: 50,
                                my: 'auto',
                                pt: 2,
                              }}
                            >
                              <AutocompleteWithFetch
                                fetch={getItemMaster}
                                handleChange={val =>
                                  handleTableChange(params?.row?._id, 'resp', val)
                                }
                                label=""
                                loading={loadingItemMaster}
                                value={formTableState[params.row._id]?.resp.name ?? ''}
                                options={filteredItemMasterOptions}
                                required
                                variant="standard"
                                size="small"
                              />
                            </Box>
                          ),
                        },
                        {
                          field: 'qty',
                          headerName: 'Quantity',
                          minWidth: 150,
                          disableColumnMenu: true,
                          disableReorder: true,
                          renderCell: params => (
                            <Box
                              sx={{
                                height: 50,
                                my: 'auto',
                                pt: 2,
                              }}
                            >
                              <NumberInputField
                                value={formTableState[params?.row?._id]?.qty ?? 0}
                                onChange={e =>
                                  handleTableChange(params?.row?._id, 'qty', e.target.value)
                                }
                                label=""
                                variant="standard"
                                size="small"
                                showErrorBgColor
                              />
                            </Box>
                          ),
                        },
                        {
                          field: 'uom',
                          headerName: 'UOM',
                          minWidth: 150,
                          disableColumnMenu: true,
                          disableReorder: true,
                          sortable: false,
                          renderCell: params => (
                            <Box
                              sx={{
                                height: 50,
                                my: 'auto',
                                pt: 2,
                              }}
                            >
                              <TextField
                                disabled
                                value={formTableState[params?.row?._id]?.resp.uom ?? ''}
                                label=""
                                variant="standard"
                                size="small"
                              />
                            </Box>
                          ),
                        },
                        {
                          field: 'cost',
                          headerName: 'ERP Cost',
                          minWidth: 150,
                          disableColumnMenu: true,
                          disableReorder: true,
                          sortable: false,
                          renderCell: params => (
                            <Box
                              sx={{
                                height: 50,
                                my: 'auto',
                                pt: 2,
                              }}
                            >
                              <TextField
                                disabled
                                value={formTableState[params?.row?._id]?.resp.cost ?? ''}
                                variant="standard"
                                size="small"
                              />
                            </Box>
                          ),
                        },
                        {
                          field: 'modifiedCost',
                          headerName: 'Cost',
                          minWidth: 150,

                          renderCell: params => (
                            <Box
                              sx={{
                                height: 50,
                                my: 'auto',
                                pt: 2,
                              }}
                            >
                              <NumberInputField
                                defaultValue={
                                  Number(formTableState[params?.row?._id]?.resp.cost) ?? 0
                                }
                                value={formTableState[params?.row?._id]?.modifiedCost}
                                onChange={e =>
                                  handleTableChange(
                                    params?.row?._id,
                                    'modifiedCost',
                                    e.target.value
                                  )
                                }
                                label=""
                                variant="standard"
                                size="small"
                                showErrorBgColor
                              />
                            </Box>
                          ),
                        },
                        {
                          field: 'amount',
                          headerName: 'Amount',
                          minWidth: 150,
                          disableColumnMenu: true,
                          disableReorder: true,
                          sortable: false,
                          renderCell: params => (
                            <Box
                              sx={{
                                height: 50,
                                my: 'auto',
                                pt: 2,
                              }}
                            >
                              <TextField
                                disabled
                                value={
                                  formTableState &&
                                  params?.row?._id &&
                                  (() => {
                                    const rowState = formTableState[params.row._id];
                                    const modifiedCost = rowState?.modifiedCost ?? 0;
                                    const qty = rowState?.qty ?? 0;
                                    const respCost = rowState?.resp?.cost ?? 0;

                                    // Use modifiedCost if defined and non-zero, otherwise use resp.cost
                                    return Number(
                                      (modifiedCost !== 0 ? modifiedCost : respCost) * qty
                                    )?.toFixed(2);
                                  })()
                                }
                                label=""
                                variant="standard"
                                size="small"
                              />
                            </Box>
                          ),
                        },
                        {
                          field: 'action',
                          headerName: 'Action',
                          minWidth: 150,
                          disableColumnMenu: true,
                          disableReorder: true,
                          sortable: false,
                          renderCell: params => (
                            <DeleteIcon
                              onClick={() => {
                                handleDeleteRow(params?.row?._id);
                              }}
                              fontSize="small"
                              sx={{
                                cursor: 'pointer',
                                display:
                                  screenType === 'update' && rows.indexOf(params.row) === 0
                                    ? 'none'
                                    : 'content',
                              }}
                            />
                          ),
                        },
                      ]}
                      getRowId={row => row._id}
                      rows={rows}
                      processRowUpdate={handleProcessRowUpdate}
                      hideFooterPagination
                    />
                  </Grid>
                </Grid>
                <Grid container position="sticky" bottom={0} right={10} pt={2} bgcolor={'white'}>
                  <Grid item sx={{ ml: 'auto' }} container justifyContent="flex-end" columnGap={1}>
                    {screenType === 'update' && prevsData !== null && (
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          setIsRevertPopupVisible(true);
                        }}
                      >
                        Revert changes
                      </Button>
                    )}
                    <Button onClick={handleAddRow} variant="outlined" color="secondary">
                      add new item
                    </Button>
                    <LoadingButton
                      variant="contained"
                      size="medium"
                      type="submit"
                      color="secondary"
                      loading={creatingRA || updatingRA}
                    >
                      {screenType} BOM
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
              {/* )} */}
            </form>
          </Grid>
        </>
      )}

      <ConfirmationPopup
        onClose={handleClosePopup}
        onConfirmation={() => {
          handleConfirmation();
        }}
        open={isPopupVisible}
        title="Do you want to select other RA Template ?"
        confirmationLabel="Yes"
        loading={loadingItemMasterByRaTemplates}
      />

      <ConfirmationPopup
        onClose={() => {
          setIsRevertPopupVisible(false);
        }}
        onConfirmation={() => {
          window.location.reload();
        }}
        open={isRevertPopupVisible}
        title="Do you want to revert the changes ?"
        confirmationLabel="Yes"
      />
    </Navbar>
  );
};

export default MapBOQRAPage;
