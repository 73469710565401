import { gql } from '@apollo/client';

export const GET_BOQS_QUERY = gql`
  query Boqs($filter: BOQFilter, $limit: Int, $page: Int) {
    getBOQs(filter: $filter, limit: $limit, page: $page) {
      boqs {
        _id
        referenceId
        project {
          name
        }
        lead {
          _id
          name
        }
        boqId
        name
        description
        uom
        qty
        cost
        margin
        discount
        wastage
        price
      }
      totalCount
      currentPage
    }
  }
`;

export const GET_BOQ_BYID_QUERY = gql`
  query GetBOQById($id: ID!) {
    getBOQById(_id: $id) {
      _id
      referenceId
      boqId
      name
      description
      uom
      qty
      cost
      margin
      discount
      wastage
      price
      rateAnalysis {
        _id
        referenceId
        raTemplateDetails
        # name
        # description
        # baseQty
        # baseUom
        totalCost
        totalPrice
        items {
          item
          name
          qty
          uom
          actualCost
          modifiedCost
          totalCost
        }
      }
    }
  }
`;
