import { NetworkStatus, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { GET_RA_TEMPLATE_QUERY } from 'graphql/query/rateAnalysisTemplate';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import RATemplate from 'components/RateAnalysisTemplate';

const RateAnalysisTemplatePage = () => {
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

  const navigate = useNavigate();
  const {
    data: raTemplate,
    networkStatus,
    refetch: refetchLeads,
  } = useQuery(GET_RA_TEMPLATE_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      filter: filters,
      limit: paginationModel.pageSize || null,
      page: paginationModel?.page + 1 || null,
    },
  });

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
    refetchLeads({
      filter: newFilters,
    });
  };

  const handlePaginationChange = (newPaginationModel: { pageSize: number; page: number }) => {
    setPaginationModel(newPaginationModel);
  };

  const loadingLeads =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!raTemplate;

  return (
    <Navbar
      goBackButtonConfig={{ title: 'Rate Analysis Templates' }}
      searchInputConfig={{
        enable: true,
        name: 'Rate analysis search',
        placeholder: 'ID / Name',
        handleChange: searchFilter =>
          applyFilters({
            ...filters,
            searchTerm: !!searchFilter ? searchFilter : null,
          }),
      }}
      actionChildren={
        <Button
          variant="contained"
          size="small"
          onClick={() => navigate(`/rateanalysis/manage-ra?type=create`)}
          color="secondary"
        >
          <AddIcon fontSize="small" />
        </Button>
      }
    >
      {loadingLeads ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <RATemplate
          RATemplate={raTemplate.getRateAnalysisTemplates?.rateAnalysisTemplates || []}
          totalCount={raTemplate.getRateAnalysisTemplates?.totalCount || 0}
          paginationModel={paginationModel}
          setPaginationModel={handlePaginationChange}
        />
      )}
    </Navbar>
  );
};

export default RateAnalysisTemplatePage;
