import { NetworkStatus, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Button, Grid, useMediaQuery } from '@mui/material';
import { GET_UOM_CONVERSION_QUERY } from 'graphql/query/conversiontable';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Filters from 'components/Filters';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import ConversionTable from 'components/RateAnalysisTemplate/ConversionTable';

const ConversionTablePage = () => {
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });
  const isMobileScreen = useMediaQuery('(max-width:600px)');

  const navigate = useNavigate();
  const {
    data: conversionTable,
    networkStatus,
    refetch: refetchLeads,
  } = useQuery(GET_UOM_CONVERSION_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      filter: filters,
    },
  });

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
    refetchLeads({
      filter: newFilters,
    });
  };

  const handlePaginationChange = (newPaginationModel: { pageSize: number; page: number }) => {
    setPaginationModel(newPaginationModel);
  };

  const loadingLeads =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!conversionTable;

  return (
    <Navbar
      goBackButtonConfig={{ title: 'Conversion Table' }}
      searchInputConfig={{
        enable: true,
        name: 'Rate Analysis search',
        placeholder: 'ID / Name',
        handleChange: searchFilter =>
          applyFilters({
            ...filters,
            searchTerm: !!searchFilter ? searchFilter : null,
          }),
      }}
      actionChildren={
        <Button
          variant="contained"
          size="small"
          onClick={() => navigate(`/rateanalysis/create-conversion`)}
          color="secondary"
        >
          <AddIcon fontSize="small" />
        </Button>
      }
    >
      <Grid
        container
        justifyContent="flex-end"
        columnSpacing={2}
        mb={2}
        pr={isMobileScreen ? 0 : 2}
        pt={isMobileScreen ? 0.5 : 0}
      >
        <Grid item xs={6} md={1.8} xl={1}>
          <Filters
            type="conversion-table"
            refetch={data => {
              applyFilters({
                raUom: data.raUom[0],
                boqUom: data.boqUom[0],
              });
            }}
          />
        </Grid>
      </Grid>

      {loadingLeads ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <ConversionTable
          ConversionTable={conversionTable.getUomConversions || []}
          paginationModel={paginationModel}
          setPaginationModel={handlePaginationChange}
        />
      )}
    </Navbar>
  );
};

export default ConversionTablePage;
