import { useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { UOMEnum } from 'types';

import DataGridTable from 'components/Table';

import { getEnumKeyFromValue } from 'utils/transformFn';

const ConversionTable: React.FC<{
  ConversionTable: any[];
  paginationModel: { pageSize: number; page: number };
  setPaginationModel: (model: { pageSize: number; page: number }) => void;
}> = ({ ConversionTable, paginationModel, setPaginationModel }) => {
  const handlePaginationChange = (newPage: number, newPageSize: number) => {
    setPaginationModel({ page: newPage, pageSize: newPageSize });
  };

  const isMobileScreen = useMediaQuery('(max-width:600px)');
  return (
    <DataGridTable
      boxSx={{ bgcolor: 'transparent ' }}
      columns={[
        {
          field: 'referenceId',
          headerName: 'ID',
          minWidth: isMobileScreen ? 150 : 250,
          renderCell: params => <Link to={params.row._id}>{params.value}</Link>,
        },
        {
          field: 'boqUom',
          headerName: 'BOQ UOM',
          minWidth: isMobileScreen ? 150 : 250,
          valueFormatter: (value: UOMEnum) => getEnumKeyFromValue(UOMEnum, value),
        },
        {
          field: 'raUom',
          headerName: 'RA UOM',
          minWidth: isMobileScreen ? 150 : 250,
          valueFormatter: (value: UOMEnum) => getEnumKeyFromValue(UOMEnum, value),
        },
        {
          field: 'conversionFactor',
          headerName: 'Conversion Factor',
          minWidth: isMobileScreen ? 150 : 250,
          valueFormatter: (value: UOMEnum) => getEnumKeyFromValue(UOMEnum, value),
        },
      ]}
      sx={{
        maxWidth: isMobileScreen ? '92vw' : '82vw',
        // mx: 'auto',
        background: 'white',
        // display: 'flex',
        // justifyContent: 'flex-start',
      }}
      rows={ConversionTable}
      getRowId={row => row._id}
      paginationMode="client"
      paginationModel={paginationModel}
      onPaginationModelChange={newModel => handlePaginationChange(newModel.page, newModel.pageSize)}
    />
  );
};

export default ConversionTable;
