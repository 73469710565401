import { Grid } from '@mui/material';
import React from 'react';
import { UOMEnum } from 'types';

import Section from 'components/Section';

import { getEnumKeyFromValue } from 'utils/transformFn';

const RASection: React.FC<{
  RATemplate: any;
}> = ({ RATemplate }) => {
  const raTemplatedetails: any[] = [
    {
      label: 'ID',
      value: RATemplate.referenceId,
      type: 'STRING',
    },
    {
      label: 'Name',
      value: RATemplate.name,
      type: 'STRING',
    },
    {
      label: 'Base Qty',
      value: RATemplate.baseQty,
      type: 'STRING',
    },
    {
      label: 'Base UOM',
      value: getEnumKeyFromValue(UOMEnum, RATemplate.baseUom),
      type: 'STRING',
    },
    {
      label: 'Description',
      value: RATemplate.description,
      type: 'STRING',
    },
  ];

  return (
    <Grid container direction="column" rowGap={1.5} mt={0}>
      <Section title="RA Template Details" collapsible data={raTemplatedetails} />
    </Grid>
  );
};

export default RASection;
