import EditIcon from '@mui/icons-material/Edit';
import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { LeadScopeEnum, LeadSegmentEnum, LeadStatusEnum } from 'types';

import { SimplePopup } from 'components/Popup';
import { RFQStatusEnum } from 'components/RFQs/RFQListView';
import UpdateRFQForm from 'components/RFQs/forms/UpdateRFQForm';
import Section, { SectionDataProps } from 'components/Section';

import { getDate } from 'utils/formatHelper';
import { getEnumKeyFromValue } from 'utils/transformFn';

const RFQSection: React.FC<{
  rfq: any;
}> = ({ rfq }) => {
  const { isSystemApproved, approvedBy } = rfq.rfqValidation || {};
  const approved = isSystemApproved
    ? 'System Approved'
    : approvedBy
    ? `${approvedBy.firstName} ${approvedBy.lastName}`
    : 'N/A';

  const [enableEditForm, toggleEditForm] = useState(false);
  const rfqDetails: SectionDataProps[] = [
    {
      label: 'Lead',
      value: rfq.name,
      type: 'LINK',
      navigateTo: `/leads/${rfq._id}`,
    },
    {
      label: 'Company',
      value: rfq.company.name,
      type: 'LINK',
      navigateTo: `/companies/${rfq.company._id}`,
    },
    {
      label: 'Status',
      value: getEnumKeyFromValue(LeadStatusEnum, rfq.leadStatus),
      type: 'STRING',
    },
    { label: 'Segment', value: getEnumKeyFromValue(LeadSegmentEnum, rfq.segment), type: 'STRING' },
    { label: 'Scope', value: getEnumKeyFromValue(LeadScopeEnum, rfq.scope), type: 'STRING' },
    { label: 'Design & Build', value: rfq.isBuildAndDesign ? 'Yes' : 'No', type: 'BOOLEAN' },
    {
      label: 'Submission Date',
      value: rfq.rfpReceivedDate ? getDate(rfq.rfpReceivedDate) : 'N/A',
      type: 'STRING',
    },
    {
      label: 'Approx Value',
      value: `₹ ${rfq.approxValue} Cr`,
      type: 'STRING',
    },
    {
      label: 'Actual Cost',
      value: rfq.totalCost ? '₹ ' + rfq.totalCost?.toFixed(2) : 'N/A',
      type: 'STRING',
    },
    {
      label: 'Actual Price',
      value: rfq.totalPrice ? '₹ ' + rfq.totalPrice?.toFixed(2) : 'N/A',
      type: 'STRING',
    },
    {
      label: 'RFQ Status',
      value: getEnumKeyFromValue(RFQStatusEnum, rfq.rfqStatus),
      type: 'STRING',
    },
    {
      label: 'Approved By',
      value: approved,
      type: 'STRING',
    },
  ];

  return (
    <Grid container direction="column" rowGap={1.5} mt={0}>
      <Section
        title="RFQ Details"
        collapsible
        data={rfqDetails}
        action={
          rfq.leadStatus === LeadStatusEnum['RFP RECEIVED'] ? (
            <EditIcon
              sx={{ cursor: 'pointer' }}
              fontSize="small"
              onClick={() => toggleEditForm(true)}
            />
          ) : undefined
        }
      />
      <SimplePopup
        onClose={() => toggleEditForm(false)}
        open={enableEditForm}
        title="Update RFQ"
        fullWidth
      >
        <UpdateRFQForm
          cb={() => {
            toggleEditForm(false);
          }}
          lead={rfq}
        />
      </SimplePopup>
    </Grid>
  );
};

export default RFQSection;
