import { gql } from '@apollo/client';
import { LeadSegmentEnum, LeadSortOptionEnum, LeadStatusEnum, MonthTypeEnum } from 'types';
import { Lead } from 'types/common';

export const GET_RFQS_QUERY = gql`
  query GetRFQs($filter: LeadFilter) {
    getRFQs(filter: $filter) {
      _id
      referenceId
      name
      leadStatus
      segment
      scope
      rfqStatus
      rfpReceivedDate
      rfpSubmittedDate
    }
  }
`;

export type GetPQsQueryResponse = {
  getRFQs: Lead[];
};

export type GetPQsQueryVariables = {
  filter: {
    leadStatus?: LeadStatusEnum[];
    monthType?: MonthTypeEnum;
    segment?: LeadSegmentEnum[];
    searchTerm?: string;
    sortOption?: LeadSortOptionEnum;
  };
};

export const GET_RFQ_BY_ID_QUERY = gql`
  query GetRFQById($id: ID!) {
    getRFQById(_id: $id) {
      _id
      referenceId
      name
      leadStatus
      segment
      scope
      totalCost
      totalPrice
      company {
        name
        _id
      }
      rfqStatus
      approxValue
      rfpReceivedDate
      rfqValidation {
        _id
        referenceId
        lead {
          _id
        }
        totalAreaForWork
        uom
        approxWorkValue
        executionTime
        advance
        onSupply
        ra
        handoverPercentage
        dlpYears
        dlpRetentionPercentage
        totalHandoverPercentage
        timeToDesign
        designRequirement
        designInvestmentRequired
        emd
        abg
        pbg
        otherBG
        paymentTermsNegotiable
        comments
        reasonForNogo
        isSystemApproved
        approvedBy {
          firstName
          lastName
        }
      }
      boqs {
        _id
        referenceId
        boqId
        name
        description
        uom
        qty
        cost
        margin
        discount
        wastage
        price
        rateAnalysis {
          _id
          referenceId
          # name
          # description
          # baseQty
          # baseUom
          items {
            item
            name
            qty
            uom
            actualCost
            modifiedCost
            totalCost
          }
        }
      }
    }
  }
`;

export type RFQQueryResponse = {
  getRFQById: {
    _id: string;
    rfqStatus: string;
  };
};

export type RFQQueryVariables = {
  id: string;
};
