import { gql } from '@apollo/client';
import { UOMEnum } from 'types';

export const CREATE_BOQ_MUTATION = gql`
  mutation CreateBOQ($input: CreateBOQInput!) {
    createBOQ(input: $input) {
      _id
      referenceId
      boqId
      name
      description
      uom
      qty
      margin
      cost
      discount
      wastage
      # price
    }
  }
`;

export const UPDATE_BOQ_MUTATION = gql`
  mutation UpdateBOQ($input: UpdateBOQInput!) {
    updateBOQ(input: $input) {
      _id
      referenceId
      boqId
      name
      description
      uom
      qty
      cost
      margin
      discount
      wastage
      # price
    }
  }
`;

export type UpdateBOQMutationResponse = {
  createBOQ: any;
};

export type UpdateBOQMutationVariables = {
  input: {
    _id: any;
    lead: any;
    boqId: string;
    name: string;
    description: string;
    qty: number;
    uom: UOMEnum;
    wastage: number | null;
    discount: number | null;
    margin: number | null;
    cost: number;
  };
};

export type CreateBOQMutationResponse = {
  createBOQ: any;
};

export type CreateBOQMutationVariables = {
  input: {
    boqId: string;
    name: string;
    description: string;
    qty: number;
    uom: UOMEnum;
    wastage: null;
    discount: null;
    margin: null;
    cost: null;
    lead: string;
  };
};
