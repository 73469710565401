import { deepOrange } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import { UOMEnum } from 'types';

import DataGridTable from 'components/Table';

import { getEnumKeyFromValue } from 'utils/transformFn';

export const BOQCreationTable: React.FC<{ boqs: []; rfqId?: string; rfqStatus: string }> = ({
  boqs,
  rfqId,
  rfqStatus,
}) => {
  return (
    <DataGridTable
      pageSizeOptions={[2, 10, 20]}
      autoPageSize
      pagination
      columns={[
        {
          field: 'referenceId',
          headerName: 'ID',
          minWidth: 100,
          renderCell: params => (
            <Link to={`/rfqs/${rfqId}/${params?.row?._id}?rfqStatus=${rfqStatus}`}>
              {params?.value}
            </Link>
          ),
        },
        {
          field: 'boqId',
          headerName: 'BOQ Id',
          minWidth: 125,
        },
        {
          field: 'name',
          headerName: 'BOQ Name',
          minWidth: 200,
        },
        {
          field: 'qty',
          headerName: 'Quantity',
          minWidth: 150,
        },
        {
          field: 'uom',
          headerName: 'UOM',
          minWidth: 150,
          valueFormatter: (value: UOMEnum) => getEnumKeyFromValue(UOMEnum, value),
        },
        {
          field: 'cost',
          headerName: 'Cost ₹',
          minWidth: 150,
          renderCell: params => params.row.cost?.toFixed(2) ?? 0,
        },
        {
          field: 'margin',
          headerName: 'Margin %',
          minWidth: 150,
          renderCell: params => params.row.margin ?? 0,
        },
        {
          field: 'wastage',
          headerName: 'Wastage %',
          minWidth: 150,
          renderCell: params => params.row.wastage ?? 0,
        },
        {
          field: 'discount',
          headerName: 'Discount %',
          minWidth: 150,
          renderCell: params => params.row.discount ?? 0,
        },
        {
          field: 'price',
          headerName: 'Price ₹',
          minWidth: 150,
          renderCell: params => params.row.price?.toFixed(2) ?? 0,
        },
      ]}
      rows={boqs}
      paginationMode="client"
      getRowId={row => row._id}
      getRowClassName={params => {
        return !params.row.rateAnalysis ? 'highlight-row' : '';
      }}
      sx={{
        height: '50vh',
        maxWidth: '80vw',
        mx: 'auto',
        mt: 0.5,
        '& .MuiDataGrid-row': {
          '&.highlight-row': {
            backgroundColor: deepOrange[50],
          },
        },
      }}
    />
  );
};
