import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Checkbox, FormControlLabel, Grid, InputAdornment } from '@mui/material';
import {
  CREATE_RFQ_VALIDATION_MUTATION,
  UPDATE_RFQ_VALIDATION_MUTATION,
} from 'graphql/mutation/rfqValidation';
import { GET_RFQ_VALIDATION_BY_LEADID } from 'graphql/query/rfqValidation';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UOMEnum } from 'types';

import Fieldset from 'components/Fieldset';
import { Select } from 'components/Inputs/Select';
import TextField, { NumberInputField } from 'components/Inputs/TextField';
import Navbar from 'components/Navbar';

const toNumber = (value: any) => (value ? Number(value) : value);

const fieldsToConvert = [
  'advance',
  'ra',
  'handoverPercentage',
  'onSupply',
  'totalAreaForWork',
  'timeToDesign',
  'approxWorkValue',
  'executionTime',
  'dlpYears',
  'totalHandoverPercentage',
  'abg',
  'pbg',
];

export enum UnitOfMeasureEnum {
  SQM = 'SQM',
  SQFT = 'SQFT',
  MW = 'MW',
  'Number of Beds' = 'NO_OF_BEDS',
}

const ManageClientValidation = () => {
  const { rfqId = '' } = useParams<{ rfqId: string }>();

  const [formState, setFormState] = useState<Record<string, any>>({
    totalAreaForWork: '',
    uom: '',
    approxWorkValue: '',
    executionTime: '',
    advance: '',
    onSupply: '',
    ra: '',
    handoverPercentage: '',
    dlpYears: '',
    dlpRetentionPercentage: '',
    totalHandoverPercentage: '',
    timeToDesign: '',
    designRequirement: false,
    designInvestmentRequired: false,
    emd: '',
    abg: 0,
    pbg: 0,
    otherBG: 'No',
    paymentTermsNegotiable: false,
    comments: '',
    reasonForNogo: '',
  });

  const queryString = useLocation()?.search;

  const params = new URLSearchParams(queryString);

  const screenType = params.get('type');

  const screenTitle = screenType === 'create' ? 'Create' : 'Update Client Evaluation';
  const [errorMessage, setErrorMessage] = useState('');

  const handleFormChanges = (fieldName: string, value: any) => {
    setFormState(prev => {
      let updatedFormState = { ...prev, [fieldName]: value };

      if (fieldName === 'emd' && !value) {
        updatedFormState = {
          ...updatedFormState,
          abg: 0,
          pbg: 0,
          otherBG: 'No',
        };
      }

      const percentageFields = ['advance', 'onSupply', 'ra', 'handoverPercentage', 'abg', 'pbg'];

      if (percentageFields.includes(fieldName)) {
        const numericValue = Number(value);
        if (!isNaN(numericValue) && numericValue > 100) {
          updatedFormState[fieldName] = '100';
        }
      }
      const total =
        Number(updatedFormState.advance || 0) +
        Number(updatedFormState.ra || 0) +
        Number(updatedFormState.handoverPercentage || 0) +
        Number(updatedFormState.onSupply || 0);

      if (total > 100) {
        setErrorMessage(
          'The total of Advance, RA, On Supply, and On Handover percentages cannot exceed 100 %.'
        );
      } else {
        setErrorMessage('');
      }

      const monthFields = ['executionTime', 'timeToDesign'];
      if (monthFields.includes(fieldName)) {
        const numericValue = Number(value);
        if (!isNaN(numericValue) && numericValue > 99) {
          updatedFormState[fieldName] = '99';
        }
      }

      const yearFields = ['dlpYears'];
      if (yearFields.includes(fieldName)) {
        const numericValue = Number(value);
        if (!isNaN(numericValue) && numericValue > 9) {
          updatedFormState[fieldName] = '9';
        }
      }

      const partialPercentagefields = ['totalHandoverPercentage'];
      if (partialPercentagefields.includes(fieldName)) {
        const numericValue = Number(value);
        if (!isNaN(numericValue) && numericValue > 99) {
          updatedFormState[fieldName] = '99';
        }
      }

      return updatedFormState;
    });
  };

  const { data, networkStatus } = useQuery<any>(GET_RFQ_VALIDATION_BY_LEADID, {
    notifyOnNetworkStatusChange: true,
    skip: screenType === 'create',
    variables: {
      leadId: rfqId,
    },
  });

  const loading =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!data;

  const goNogoData = data?.getRFQValidationByLeadId;

  const [createRFQValidation, { loading: creatingRFQValidation }] = useMutation<any>(
    CREATE_RFQ_VALIDATION_MUTATION
  );
  const [updateRFQValidation, { loading: updatingRFQValidation }] = useMutation<any>(
    UPDATE_RFQ_VALIDATION_MUTATION
  );

  const calGoNogo =
    Number(formState?.advance || 0) +
    Number(formState?.ra || 0) +
    Number(formState?.handoverPercentage || 0) +
    Number(formState?.onSupply || 0);

  const lessThanExpectedValue = Number(calGoNogo) <= 75 ? true : false;

  const defaultDLPRetentionPercentage = calGoNogo === 0 ? 100 : (100 - calGoNogo).toFixed(2);

  const navigate = useNavigate();

  const handleFormSubmission = () => {
    try {
      if (screenType === 'edit') {
        // Call update API
        const input = {
          _id: goNogoData?._id,
          ...{ ...formState, dlpRetentionPercentage: toNumber(defaultDLPRetentionPercentage) },
          ...Object.fromEntries(fieldsToConvert.map(field => [field, toNumber(formState[field])])),
        };
        updateRFQValidation({
          variables: {
            input,
          },
          onCompleted: _ => {
            navigate(`/rfqs/${rfqId}`, { replace: true });
          },
        });
      } else {
        // Call create API

        const input = {
          lead: rfqId,
          ...formState,
          ...Object.fromEntries(fieldsToConvert.map(field => [field, toNumber(formState[field])])),
        };

        createRFQValidation({
          variables: { input },
          onCompleted: () => {
            navigate(`/rfqs/${rfqId}`, { replace: true });
          },
        });
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  // Function to check if all fields are filled
  const isFormComplete = () => {
    return Object.keys(formState).every(key => {
      const value = formState[key];
      // For strings and numbers, check if they are not empty or zero
      if (typeof value === 'string' || typeof value === 'number') {
        return value !== '' && value !== 0;
      }
      // For booleans, check if they are true (assuming false means not filled)
      if (typeof value === 'boolean') {
        return value !== false;
      }
      return false;
    });
  };

  useEffect(() => {
    setIsButtonDisabled(!isFormComplete());
  }, [formState]);

  useEffect(() => {
    if (!loading) {
      if (goNogoData) {
        // Data exists -> Update Mode

        setFormState({
          totalAreaForWork: goNogoData.totalAreaForWork ?? '',
          uom: goNogoData.uom ?? '',
          approxWorkValue: goNogoData.approxWorkValue ?? '',
          executionTime: goNogoData.executionTime ?? '',
          advance: goNogoData.advance ?? '',
          onSupply: goNogoData.onSupply ?? '',
          ra: goNogoData.ra ?? '',
          handoverPercentage: goNogoData.handoverPercentage ?? '',
          dlpYears: goNogoData.dlpYears ?? '',
          dlpRetentionPercentage: goNogoData?.dlpRetentionPercentage ?? '',
          totalHandoverPercentage: goNogoData.totalHandoverPercentage ?? '',
          timeToDesign: goNogoData.timeToDesign ?? '',
          designRequirement: goNogoData.designRequirement ?? false,
          designInvestmentRequired: goNogoData.designInvestmentRequired ?? false,
          emd: goNogoData.emd ?? '',
          abg: goNogoData.abg ?? '',
          pbg: goNogoData.pbg ?? '',
          otherBG: goNogoData.otherBG ?? '',
          paymentTermsNegotiable: goNogoData.paymentTermsNegotiable ?? false,
          comments: goNogoData.comments ?? '',
          reasonForNogo: goNogoData.reasonForNogo ?? '',
        });
      } else {
        // No data -> Create Mode

        setFormState({
          totalAreaForWork: '',
          uom: '',
          approxWorkValue: '',
          executionTime: '',
          advance: '',
          onSupply: '',
          ra: '',
          handoverPercentage: '',
          dlpYears: '',
          dlpRetentionPercentage: defaultDLPRetentionPercentage,
          totalHandoverPercentage: '',
          timeToDesign: '',
          designRequirement: false,
          designInvestmentRequired: false,
          emd: '',
          abg: '',
          pbg: '',
          otherBG: '',
          paymentTermsNegotiable: false,
          comments: '',
          reasonForNogo: '',
        });
      }
    }
  }, [loading, goNogoData]);

  return (
    <Navbar
      goBackButtonConfig={{
        title: screenTitle,
      }}
    >
      <Grid item container direction="column" xs={12} md={11} lg={9} xl={6} mt={0.5} rowGap={2.5}>
        <form
          style={{ maxHeight: '50vh', padding: '5px' }}
          onSubmit={e => {
            e.preventDefault();
            handleFormSubmission();
          }}
        >
          <Grid container direction="column" gap={2} px={2} py={1}>
            <Grid container columns={12} columnSpacing={2} rowGap={2}>
              <Grid item xs={6}>
                <NumberInputField
                  label="Total Area for work"
                  value={formState.totalAreaForWork ?? ''}
                  onChange={e => handleFormChanges('totalAreaForWork', e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <Select
                  label="Units"
                  value={formState?.uom ?? ''}
                  onChange={val => handleFormChanges('uom', val)}
                  required
                  options={Object.keys(UOMEnum)?.map(o => ({
                    label: o,
                    value: UOMEnum[o],
                  }))}
                />
              </Grid>
            </Grid>
            <Grid container columns={12} columnSpacing={2} rowGap={2}>
              <Grid item xs={6}>
                <NumberInputField
                  type="number"
                  label="Approx work value"
                  value={formState.approxWorkValue ?? ''}
                  onChange={e => handleFormChanges('approxWorkValue', e.target.value)}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <b color="lightGray">₹</b>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <NumberInputField
                  type="number"
                  value={formState.executionTime}
                  onChange={e => handleFormChanges('executionTime', e.target.value)}
                  label="Execution Time In Months"
                  required
                />
              </Grid>
            </Grid>

            <Fieldset label="Payment Terms">
              <Grid columns={12} container columnSpacing={2} rowGap={2}>
                <Grid item xs={6}>
                  <NumberInputField
                    error={errorMessage ? true : false}
                    type="number"
                    label="Advance %"
                    value={formState.advance ?? ''}
                    onChange={e => handleFormChanges('advance', e.target.value)}
                    isPercentage
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumberInputField
                    error={errorMessage ? true : false}
                    label="On Supply %"
                    type="number"
                    value={formState.onSupply ?? ''}
                    onChange={e => handleFormChanges('onSupply', e.target.value)}
                    isPercentage
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumberInputField
                    error={errorMessage ? true : false}
                    value={formState.ra ?? ''}
                    onChange={e => handleFormChanges('ra', e.target.value)}
                    label="RA %"
                    type="number"
                    isPercentage
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumberInputField
                    error={errorMessage ? true : false}
                    type="number"
                    value={formState.handoverPercentage ?? ''}
                    onChange={e => handleFormChanges('handoverPercentage', e.target.value)}
                    label="On Handover %"
                    isPercentage
                    required
                  />
                </Grid>
                {errorMessage && (
                  <Grid item xs={12}>
                    <div style={{ color: 'red', fontSize: '15px' }}>{errorMessage}</div>
                  </Grid>
                )}
                <Grid item xs={6}>
                  <NumberInputField
                    value={formState.dlpYears ?? ''}
                    onChange={e => handleFormChanges('dlpYears', e.target.value)}
                    label="DLP in years"
                    type="number"
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumberInputField
                    disabled
                    value={defaultDLPRetentionPercentage}
                    label="DLP retention %"
                    isPercentage
                  />
                </Grid>

                <Grid item xs={6}>
                  <NumberInputField
                    label="How much total do we get on handover %"
                    type="number"
                    value={formState.totalHandoverPercentage ?? ''}
                    onChange={e => handleFormChanges('totalHandoverPercentage', e.target.value)}
                    isPercentage
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumberInputField
                    type="number"
                    value={formState.timeToDesign ?? ''}
                    onChange={e => handleFormChanges('timeToDesign', e.target.value)}
                    label="Time to design in months"
                    required
                  />
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.designRequirement ?? false}
                          onChange={e => handleFormChanges('designRequirement', e.target.checked)}
                          color="secondary"
                        />
                      }
                      label="Design Requirement"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.designInvestmentRequired ?? false}
                          onChange={e =>
                            handleFormChanges('designInvestmentRequired', e.target.checked)
                          }
                          color="secondary"
                        />
                      }
                      label="Any Design Investment required"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.paymentTermsNegotiable ?? false}
                        onChange={e =>
                          handleFormChanges('paymentTermsNegotiable', e.target.checked)
                        }
                        color="secondary"
                      />
                    }
                    label="Are payment terms negotiable ?"
                  />
                </Grid>
                <Grid item xs={6} ml={-1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.emd ?? false}
                        onChange={e => handleFormChanges('emd', e.target.checked)}
                        color="secondary"
                      />
                    }
                    label="Any EMD"
                  />
                </Grid>

                {formState.emd && (
                  <>
                    <Grid item xs={6}>
                      <NumberInputField
                        value={formState.abg ?? 0}
                        onChange={e => handleFormChanges('abg', e.target.value)}
                        label="ABG %"
                        type="number"
                        isPercentage
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <NumberInputField
                        value={formState.pbg ?? 0}
                        onChange={e => handleFormChanges('pbg', e.target.value)}
                        label="PBG %"
                        type="number"
                        isPercentage
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        value={formState.otherBG ?? 'No'}
                        onChange={e => handleFormChanges('otherBG', e.target.value)}
                        label="Any other BG"
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <TextField
                    value={formState.comments ?? ''}
                    onChange={e => handleFormChanges('comments', e.target.value)}
                    label="Comments"
                    required
                    multiline
                    minRows={2}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!formState.goornogo}
                        onChange={e => handleFormChanges('goornogo', e.target.checked)}
                        color="primary"
                      />
                    }
                    label="Go or no go"
                  />
                </Grid> */}
                {/* {lessThanExpectedValue && (
                  <Grid item xs={12}>
                    <TextField
                      value={formState.reasonForNogo ?? ''}
                      onChange={e => handleFormChanges('reasonForNogo', e.target.value)}
                      label="Reason for no go"
                      // required={lessThanExpectedValue}
                      multiline
                      minRows={2}
                    />
                  </Grid>
                )} */}
              </Grid>
            </Fieldset>

            <LoadingButton
              type="submit"
              variant="contained"
              color="secondary"
              loading={creatingRFQValidation || updatingRFQValidation}
              disabled={creatingRFQValidation || updatingRFQValidation || !!errorMessage}
            >
              {/* {isEditMode ? 'Update Go/Nogo' : 'Create Go / Nogo'} */}
              {screenType === 'edit'
                ? 'Update Client Evaluation'
                : lessThanExpectedValue
                ? 'Submit For Approval'
                : 'Go ahead'}
            </LoadingButton>
          </Grid>
        </form>
      </Grid>
    </Navbar>
  );
};

export default ManageClientValidation;
