import { useState } from 'react';

/**
 * @link https://usehooks-ts.com/react-hook/use-previous for more info
 */
export const usePreviousPersistent = value => {
  const [state, setState] = useState({
    value: value,
    prev: null,
  });

  const current = state.value;

  if (value !== current) {
    setState({
      value: value,
      prev: current,
    });
  }

  return state.prev;
};
