import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { DataGrid, DataGridProps, GridColDef } from '@mui/x-data-grid';
import * as React from 'react';

type DataGridTableProps = {
  rows: Record<string, any>[];
  columns: GridColDef[];
  boxSx?: any;
} & DataGridProps;

const DataGridTable: React.FC<DataGridTableProps> = ({ columns, rows, boxSx = {}, ...props }) => {
  return (
    <Box sx={{ height: '100%', width: '100%', bgcolor: 'white', ...boxSx }}>
      <DataGrid rows={rows} columns={columns} disableRowSelectionOnClick {...props} />
    </Box>
  );
};

export default DataGridTable;
