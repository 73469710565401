import { gql } from '@apollo/client';

export const GET_ITEM_MASTER_BY_RA_TEMPLATE_AND_BOQ = gql`
  query GetItemMastersByRaTemplateAndBoq($raTemplatedId: ID!, $boqId: ID!) {
    getItemMastersByRaTemplateAndBoq(raTemplatedId: $raTemplatedId, boqId: $boqId) {
      name
      qty
      uom
      cost
      item {
        _id
        referenceId
        code
        codeDescription
        subGroup
        subGroupDescription
        resourceCode
        type
        description
        uom
        cost
      }
    }
  }
`;

export const GET_RA_TEMPLATE_QUERY_WITHOUT_FIELDS = gql`
  query GetRateAnalysisTemplates($filter: RateAnalysisTemplateFilter, $limit: Int, $page: Int) {
    getRateAnalysisTemplates(filter: $filter, limit: $limit, page: $page) {
      rateAnalysisTemplates {
        _id
        referenceId
        name
        # description
        # baseQty
        baseUom
        # items {
        #   item {
        #     _id
        #     referenceId
        #     cost
        #     uom
        #     description
        #     type
        #   }
        #   name
        #   qty
        #   uom
        #   cost
        # }
      }
      totalCount
      totalPages
      currentPage
    }
  }
`;

export const GET_RA_TEMPLATE_QUERY = gql`
  query GetRateAnalysisTemplates($filter: RateAnalysisTemplateFilter, $limit: Int, $page: Int) {
    getRateAnalysisTemplates(filter: $filter, limit: $limit, page: $page) {
      rateAnalysisTemplates {
        _id
        referenceId
        name
        description
        baseQty
        baseUom
        items {
          item {
            _id
            referenceId
            cost
            uom
            description
            type
          }
          name
          qty
          uom
          cost
        }
      }
      totalCount
      totalPages
      currentPage
    }
  }
`;

export type GetRATemplateQueryResponse = {
  getRateAnalysisTemplates: any[];
  totalCount: number;
  totalPages: number;
  currentPage: number;
};

export type GetRATemplateQueryVariables = {
  filter: {
    searchTerm?: string;
    limit: number | null;
    page: number | null;
  };
};

export const GET_RA_TEMPLATE_BY_ID_QUERY = gql`
  query GetRateAnalysisTemplateById($id: ID!) {
    getRateAnalysisTemplateById(_id: $id) {
      _id
      referenceId
      name
      description
      baseQty
      baseUom
      items {
        item {
          _id
          referenceId
          cost
          uom
          description
          type
        }
        name
        qty
        cost
        uom
      }
    }
  }
`;

export type RATemplateDetailsQueryResponse = {
  getRateAnalysisTemplateById: any;
};

export type RATemplateDetailsQueryVariables = {
  id: string;
};
