import { NetworkStatus, useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import {
  GET_RA_TEMPLATE_BY_ID_QUERY,
  RATemplateDetailsQueryResponse,
  RATemplateDetailsQueryVariables,
} from 'graphql/query/rateAnalysisTemplate';
import { useParams } from 'react-router-dom';

import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import RASection from 'components/RateAnalysisTemplate/Sections/RASection';
import TemplateItemSection from 'components/RateAnalysisTemplate/Sections/TemplateItems';

const RATemplateDetailsPage = () => {
  const { rateAnalysisId = '' } = useParams<{ rateAnalysisId: string }>();

  const { data: RATemplate, networkStatus } = useQuery<
    RATemplateDetailsQueryResponse,
    RATemplateDetailsQueryVariables
  >(GET_RA_TEMPLATE_BY_ID_QUERY, {
    variables: {
      id: rateAnalysisId,
    },
    nextFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const loadingPQ =
    NetworkStatus.loading === networkStatus ||
    NetworkStatus.setVariables === networkStatus ||
    !!!RATemplate;

  return (
    <Navbar
      goBackButtonConfig={{
        title: 'RA Details',
      }}
    >
      {loadingPQ ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Grid item container direction="column" xs={12} md={11} lg={9} xl={7} mb={5} rowGap={2.5}>
          <RASection RATemplate={RATemplate?.getRateAnalysisTemplateById} />
          <TemplateItemSection
            raTemplate={RATemplate?.getRateAnalysisTemplateById?.items}
            rateAnalysisId={rateAnalysisId}
          />
        </Grid>
      )}
    </Navbar>
  );
};

export default RATemplateDetailsPage;
