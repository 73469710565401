import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UOMEnum } from 'types';

import { Select } from 'components/Inputs/Select';
import TextField from 'components/Inputs/TextField';
import Navbar from 'components/Navbar';
import Note from 'components/Note';

const CREATE_UOM_CONVERSION_MUTATION = gql`
  mutation CreateUomConversion($input: CreateUomConversionInput!) {
    createUomConversion(input: $input) {
      _id
      boqUom
      raUom
      conversionFactor
    }
  }
`;

const CreateConversions = () => {
  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    boqUOM: '',
    raUOM: '',
    conversionFactor: '',
  });

  const [createUomConversion, { loading }] = useMutation(CREATE_UOM_CONVERSION_MUTATION);

  const handleChange = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleSubmit = () => {
    const input = {
      boqUom: formState.boqUOM,
      raUom: formState.raUOM,
      conversionFactor: parseFloat(formState.conversionFactor),
    };

    createUomConversion({
      variables: { input },
      onCompleted: data => {
        console.log('Conversion Created:', data);
        navigate('/rateanalysis/conversion-table');
      },
      onError: error => {
        console.error('Error creating conversion:', error.message);
      },
    });
  };

  return (
    <Navbar goBackButtonConfig={{ title: 'Create Conversion' }}>
      <Grid
        item
        container
        direction="column"
        xs={12}
        md={11}
        lg={9}
        xl={6}
        mt={0.5}
        rowGap={2.5}
        ml={2}
      >
        <Note title="The Conversion is from BOQ UOM to RA UOM" />

        <form
          onSubmit={e => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Select
                label="BOQ UOM"
                fullWidth
                value={formState.boqUOM}
                options={Object.keys(UOMEnum).map(key => ({ label: key, value: UOMEnum[key] }))}
                onChange={value => handleChange('boqUOM', value)}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                label="RA UOM"
                fullWidth
                value={formState.raUOM}
                options={Object.keys(UOMEnum).map(key => ({ label: key, value: UOMEnum[key] }))}
                onChange={value => handleChange('raUOM', value)}
                required
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={1}>
            <Grid item xs={12}>
              <TextField
                label="Conversion Factor"
                value={formState.conversionFactor}
                onChange={e => handleChange('conversionFactor', e.target.value)}
                required
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end" mt={2}>
            <LoadingButton type="submit" variant="contained" loading={loading}>
              Submit
            </LoadingButton>
          </Grid>
        </form>
      </Grid>
    </Navbar>
  );
};

export default CreateConversions;
