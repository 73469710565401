import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Box,
  Card,
  CardContent,
  CardProps,
  ClickAwayListener,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Fieldset from 'components/Fieldset';

import { convertAmountToIndianUnits } from 'utils/formatHelper';
import getStatusDetail from 'utils/statusMessage';

import theme from './theme.module.scss';

type SectionType = 'contained' | 'outlined';

export type SectionTypes =
  | 'DATE'
  | 'DATETIME'
  | 'STRING'
  | 'CURRENCY'
  | 'IMAGE'
  | 'NUMBER'
  | 'STATUS'
  | 'FILE'
  | 'SELECT'
  | 'CUSTOM'
  | 'PRODUCTS'
  | 'LINK'
  | 'EXTERNAL_LINK'
  | 'NODE'
  | 'BOOLEAN'
  | 'SECTION';

export type MultiSectionDataProps = {
  label: string;
  value: any;
  type?: SectionTypes;
  navigateTo?: string;
};

export type MultiSectionCardProps = {
  title: string;
  data?: MultiSectionDataProps[];
  action?: React.ReactNode | { title: string; onClick: () => void; disabled: boolean }[];
  children?: React.ReactNode;
  variant?: SectionType;
  collapsible?: boolean;
  defaultCollapsed?: boolean;
  endAdornment?: React.ReactNode;
} & Omit<CardProps, 'variant'>;

export const getSectionValue = (value: any, type?: SectionTypes, navigateTo?: string) => {
  switch (type) {
    case 'CURRENCY':
      return convertAmountToIndianUnits(value);
    case 'DATE':
      return !!value ? dayjs(value).format('DD MMM YYYY') : <i>None</i>;
    case 'DATETIME':
      return dayjs(value).format('h:mm A, DD MMMM YYYY');
    case 'FILE':
      return value ? (
        <a href={value} rel="noreferrer" target="_blank">
          Download
        </a>
      ) : (
        'Not Available'
      );
    case 'IMAGE':
      return (
        <div
          className={theme.image}
          style={{
            backgroundImage: `url("${value}")`,
          }}
        />
      );
    case 'NUMBER':
      return !!value ? value : <i>None</i>;
    case 'STATUS':
      return getStatusDetail(value).labelWithBadge;
    case 'LINK':
      return (
        <Link style={{ textDecoration: 'underline', textUnderlineOffset: 3 }} to={navigateTo || ''}>
          {value}
        </Link>
      );
    case 'EXTERNAL_LINK':
      // Only render link if navigateTo is not null/undefined or '#'
      return navigateTo && navigateTo !== '#' ? (
        <a href={navigateTo} rel="noreferrer" target="_blank">
          {value}
        </a>
      ) : (
        <i>-</i>
      );
    case 'BOOLEAN':
      return !!value ? 'Yes' : 'No';
    default:
      return !!value ? value : <i>None</i>;
  }
};

const MultiSectionCard: React.FC<MultiSectionCardProps> = ({
  action,
  data = [],
  title,
  children,
  variant = 'contained',
  collapsible = false,
  defaultCollapsed = false,
  endAdornment,
  ...props
}) => {
  const [show, toggleShow] = useState(!defaultCollapsed);
  const isMobileScreen = useMediaQuery('(max-width:600px)');

  const renderSectionData = (data: MultiSectionDataProps[]) =>
    data.map(d => (
      <Grid container columns={2} alignItems="start" key={d.label} sx={{ mb: 1 }}>
        <Grid item xs={1}>
          <Typography variant="caption" fontWeight={600} color={grey[700]}>
            {d.label}
          </Typography>
          {d.type === 'SECTION' && Array.isArray(d.value) && (
            <Grid
              px={3}
              py={1}
              width={isMobileScreen ? '100vw' : '46vw'}
              justifyContent={'flex-end'}
              overflow="hidden"
            >
              {renderSectionData(d.value)}
            </Grid>
          )}
        </Grid>
        {d.type !== 'SECTION' && (
          <Grid item xs={1} overflow="hidden">
            <Typography variant="caption" fontWeight={600}>
              {getSectionValue(d.value, d.type, d.navigateTo)}
            </Typography>
          </Grid>
        )}
      </Grid>
    ));

  return (
    <Box width="100%">
      <Card elevation={3} {...props}>
        <CardContent
          sx={{ padding: 0 }}
          className={classNames(
            theme.sectionContainer,
            collapsible ? (show ? theme.sectionExpand : theme.sectionCollapse) : ''
          )}
        >
          {/* Header */}
          <Grid
            bgcolor={theme =>
              variant === 'contained' ? theme.palette.secondary.light : theme.palette.common.white
            }
            color={theme =>
              variant === 'contained' ? theme.palette.common.white : theme.palette.secondary.light
            }
            display="flex"
            justifyContent="space-between"
            px={1}
            pt={0.3}
            pb={0.2}
            alignItems="center"
            sx={{ boxShadow: 2 }}
            position="relative"
          >
            <Grid
              item
              container
              alignItems="center"
              columnGap={0.7}
              sx={{ cursor: collapsible ? 'pointer' : 'auto' }}
              onClick={() => toggleShow(prev => !prev)}
            >
              <Typography variant="subtitle1" fontWeight={600}>
                {title}
              </Typography>
              {!!endAdornment ? endAdornment : null}
            </Grid>
            <Grid item display="flex" alignItems="center">
              {Array.isArray(action) ? <Actions variant={variant} actions={action} /> : action}
              {collapsible ? (
                <IconButton
                  sx={{ cursor: 'pointer' }}
                  onClick={() => toggleShow(prev => !prev)}
                  color="inherit"
                >
                  {show ? (
                    <ArrowUpwardIcon fontSize="small" />
                  ) : (
                    <ArrowDownwardIcon fontSize="small" />
                  )}
                </IconButton>
              ) : null}
            </Grid>
          </Grid>

          {/* Data Rendering */}
          <Grid
            container
            direction="column"
            // xs={12}
            rowGap={0.2}
            mt={1.5}
            pl={2}
            width={isMobileScreen ? '100vw' : '46vw'}
          >
            {renderSectionData(data)}
          </Grid>

          {/* Children Rendering */}
          {children && <Box px={2}>{children}</Box>}
        </CardContent>
      </Card>
    </Box>
  );
};

const Actions: React.FC<{
  actions: { title: string; onClick: () => void; disabled: boolean }[];
  variant: SectionType;
}> = ({ actions, variant }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <div style={{ color: '#ffffff' }}>
        <IconButton onClick={handleClick} color="inherit" sx={{ p: 0 }}>
          <MoreHorizIcon color={variant === 'contained' ? 'inherit' : 'primary'} />
        </IconButton>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <List>
            {actions.map((item, index) => (
              <ListItemButton
                key={index}
                onClick={
                  !item.disabled
                    ? () => {
                        item.onClick();
                        handleClose();
                      }
                    : undefined
                }
              >
                <ListItemText
                  primary={item.title}
                  primaryTypographyProps={{
                    sx: {
                      cursor: item.disabled ? 'not-allowed' : 'pointer',
                    },
                  }}
                />
              </ListItemButton>
            ))}
          </List>
        </ClickAwayListener>
      </Popover>
    </>
  );
};

export default MultiSectionCard;
