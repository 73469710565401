import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Lead, RFPSubmitTypeEnum } from 'types';

import DateInput from 'components/Inputs/Date';
import { Select } from 'components/Inputs/Select';

import { formatDate } from 'utils/transformFn';

const SUBMIT_RFQ_MUTATION = gql`
  mutation SubmitRFQ($input: SubmitRFQInput!) {
    submitRFQ(input: $input) {
      _id
      name
      leadStatus
      rfpReceivedDate
      rfpSubmittedDate
      rfpSubmitType
    }
  }
`;

type SubmitRFQMutationResponse = {
  submitRFQ: any;
};

type SubmitRFQMutationVariables = {
  input: {
    lead: string;
    rfpSubmitType: RFPSubmitTypeEnum;
    rfpSubmittedDate: string;
  };
};

const SubmitRFQForm: React.FC<{ lead: Lead; cb: (lead: Lead) => void }> = ({ cb, lead }) => {
  const [formState, setFormState] = useState({
    rfpSubmitType: RFPSubmitTypeEnum.ONLINE,
    rfpSubmittedDate: dayjs(),
  });

  const [submitPQ, { loading: submittingPQ }] = useMutation<
    SubmitRFQMutationResponse,
    SubmitRFQMutationVariables
  >(SUBMIT_RFQ_MUTATION);

  const handleSubmit = () => {
    const rfpSubmittedDate = formatDate(formState.rfpSubmittedDate) as string;

    submitPQ({
      variables: {
        input: {
          lead: lead._id,
          rfpSubmitType: formState.rfpSubmitType,
          rfpSubmittedDate,
        },
      },
      onCompleted: updatedLead => {
        cb(updatedLead.submitRFQ);
      },
      onError: error => {
        console.error('GraphQL error:', error);
      },
    });
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container columnSpacing={2} rowGap={2} px={2} py={2}>
        <Grid item xs={6}>
          <Select
            options={Object.keys(RFPSubmitTypeEnum).map(o => ({
              label: o,
              value: RFPSubmitTypeEnum[o],
            }))}
            required
            label="RFQ Submit Type"
            value={formState.rfpSubmitType}
            onChange={val => {
              if (val === RFPSubmitTypeEnum.ONLINE) {
                setFormState({
                  rfpSubmitType: val,
                  rfpSubmittedDate: dayjs(),
                });
              } else {
                setFormState(prev => ({
                  ...prev,
                  rfpSubmitType: val,
                }));
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <DateInput
            required
            value={formState.rfpSubmittedDate}
            onChange={val => setFormState(prev => ({ ...prev, rfpSubmittedDate: val }))}
            label="RFQ Submit Date"
            disabled={formState.rfpSubmitType === RFPSubmitTypeEnum.ONLINE}
          />
        </Grid>
        <Grid item container xs={12} justifyContent="flex-end">
          <LoadingButton loading={submittingPQ} type="submit" variant="contained">
            Submit BOQ
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default SubmitRFQForm;
