import { gql, useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React from 'react';
import { Lead, LeadStatusEnum } from 'types';

import { FormInput, FormPanel } from 'components/FormPanel';

import { formatDate, getEnumKeyFromValue } from 'utils/transformFn';

const UPDATE_RFQ_MUTATION = gql`
  mutation UpdateRFQ($input: UpdateRFQInput!) {
    updateRFQ(input: $input) {
      _id
      rfpReceivedDate
      rfpSubmittedDate
      rfqStatus
    }
  }
`;

type UpdateRFQMutationResponse = {
  updateRFQ: any;
};

type UpdateRFQMutationVariables = {
  input: {
    lead: string;
    rfpReceivedDate: string;
  };
};

const UpdateRFQForm: React.FC<{ lead: Lead; cb: (updatedLead: Lead) => void }> = ({ cb, lead }) => {
  const [updateRFQ, { loading: updatingPQ }] = useMutation<
    UpdateRFQMutationResponse,
    UpdateRFQMutationVariables
  >(UPDATE_RFQ_MUTATION);

  const handleSubmit = (data: Record<string, any>) => {
    const rfpReceivedDate = formatDate(data.rfqReceivedDate) as string;

    updateRFQ({
      variables: {
        input: {
          lead: lead._id,
          rfpReceivedDate,
        },
      },
      onCompleted: updatedLead => {
        cb(updatedLead.updateRFQ);
      },
      onError: error => {
        console.error('GraphQL error:', error);
      },
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container direction="column" rowGap={2} px={2} py={2}>
        <FormPanel
          error={null}
          loading={updatingPQ}
          onSubmit={handleSubmit}
          submitButtonLabel="Update RFQ"
        >
          <FormInput
            fieldName="leadStatus"
            label="Lead Status"
            type="string"
            disabled
            defaultValue={getEnumKeyFromValue(LeadStatusEnum, lead.leadStatus)}
            validators={{
              required: true,
            }}
          />
          <FormInput
            fieldName="rfqReceivedDate"
            label="RFQ Submission Date"
            type="date"
            defaultValue={!!lead.rfpReceivedDate ? dayjs(lead.rfpReceivedDate) : null}
            validators={{
              required: true,
            }}
          />
        </FormPanel>
      </Grid>
    </LocalizationProvider>
  );
};

export default UpdateRFQForm;
