import { gql } from '@apollo/client';

export const CREATE_RATE_ANALYSIS_TEMPLATE_MUTATION = gql`
  mutation CreateRateAnalysisTemplate($input: CreateRateAnalysisTemplateInput!) {
    createRateAnalysisTemplate(input: $input) {
      _id
      referenceId
      name
      description
      baseQty
      baseUom
      items {
        name
        qty
        cost
        uom
        item {
          _id
          referenceId
          code
          codeDescription
          subGroup
          subGroupDescription
          resourceCode
          type
          description
          uom
          cost
        }
      }
    }
  }
`;

export const UPDATE_RATE_ANALYSIS_TEMPLATE_MUTATION = gql`
  mutation UpdateRateAnalysisTemplate($input: UpdateRateAnalysisTemplateInput!) {
    updateRateAnalysisTemplate(input: $input) {
      _id
      referenceId
      name
      description
      baseQty
      baseUom
      items {
        name
        qty
        cost
        uom
        item {
          _id
          # referenceId
          code
          codeDescription
          subGroup
          subGroupDescription
          resourceCode
          type
          description
          uom
          cost
        }
      }
    }
  }
`;
