import { gql, useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Company } from 'types';

import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import ConversionSection from 'components/RateAnalysisTemplate/Sections/ConversionSection';

const UOM_CONVERSION_BY_ID_QUERY = gql`
  query GetUomConversionById($id: ID!) {
    getUomConversionById(_id: $id) {
      _id
      boqUom
      conversionFactor
      raUom
      referenceId
    }
  }
`;

type ConversionQueryResponse = {
  getUomConversionById: Company;
};

type ConversionQueryVariables = {
  id: string;
};

const ConversionDetailsPage = () => {
  const { conversionId = '' } = useParams<{ conversionId: string }>();
  const navigate = useNavigate();

  const { data: conversion, loading: loadingConversion } = useQuery<
    ConversionQueryResponse,
    ConversionQueryVariables
  >(UOM_CONVERSION_BY_ID_QUERY, {
    variables: {
      id: conversionId,
    },
  });

  const loading = loadingConversion || !!!conversion;

  return (
    <Navbar
      goBackButtonConfig={{
        title: loading ? 'Conversion Details' : 'Conversion Details',
        onClick: () => navigate('/rateanalysis/conversion-table'),
      }}
    >
      {loading ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Grid item container direction="column" xs={12} md={11} lg={9} xl={7} mb={2} rowGap={2.5}>
          <ConversionSection conversion={conversion.getUomConversionById} />
        </Grid>
      )}
    </Navbar>
  );
};

export default ConversionDetailsPage;
