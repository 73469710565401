import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import BusinessIcon from '@mui/icons-material/Business';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import DescriptionIcon from '@mui/icons-material/Description';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import GppGoodIcon from '@mui/icons-material/GppGood';
import GroupsIcon from '@mui/icons-material/Groups';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import TuneIcon from '@mui/icons-material/Tune';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Box } from '@mui/material';
import { useUser } from 'contexts/userContext';
import { FC, useMemo } from 'react';

import Sidebar from 'components/Sidebar';

type SideMenuTabsType = {
  key: string;
  label: string;
  icon: JSX.Element;
  route: string;
};

const ResponsiveSidebarLayout: FC<{ children: any }> = props => {
  const { user } = useUser();

  const tabs = useMemo(() => {
    const assignedTabs: SideMenuTabsType[] = [];
    if (!!!user) return assignedTabs;

    switch (user.role.name) {
      case 'EXTERNAL_USER':
        assignedTabs.push(
          {
            key: 'documents',
            label: 'Document Master',
            icon: <FileCopyIcon fontSize="small" />,
            route: '/documents',
          },
          {
            key: 'settings',
            label: 'Settings',
            icon: <TuneIcon fontSize="small" />,
            route: '/settings',
          }
        );
        break;
      case 'PQ_USER':
        assignedTabs.push(
          {
            key: 'pqs',
            label: 'PQs',
            icon: <DescriptionIcon fontSize="small" />,
            route: '/pqs',
          },
          {
            key: 'documents',
            label: 'Document Master',
            icon: <FileCopyIcon fontSize="small" />,
            route: '/documents',
          },
          {
            key: 'settings',
            label: 'Settings',
            icon: <TuneIcon fontSize="small" />,
            route: '/settings',
          }
        );
        break;
      case 'ADMIN':
        assignedTabs.push(
          {
            key: 'dashboard',
            label: 'Dashboard',
            icon: <SpaceDashboardIcon fontSize="small" />,
            route: '/',
          },
          {
            key: 'companies',
            label: 'Companies',
            icon: <BusinessIcon fontSize="small" />,
            route: '/companies',
          },
          {
            key: 'leads',
            label: 'Leads',
            icon: <AccountTreeIcon fontSize="small" />,
            route: '/leads',
          },
          {
            key: 'followups',
            label: 'Events',
            icon: <GroupsIcon fontSize="small" />,
            route: '/followups',
          },
          {
            key: 'documents',
            label: 'Document Master',
            icon: <FileCopyIcon fontSize="small" />,
            route: '/documents',
          },
          {
            key: 'pqs',
            label: 'PQs',
            icon: <DescriptionIcon fontSize="small" />,
            route: '/pqs',
          },
          {
            key: 'rfqs',
            label: 'RFQs',
            icon: <RequestQuoteIcon fontSize="small" />,
            route: '/rfqs',
          },
          {
            key: 'rateanalysis',
            label: 'Rate Analysis',
            icon: <CurrencyRupeeIcon fontSize="small" />,
            route: '/rateanalysis',
          },
          {
            key: 'compliances',
            label: 'Compliances',
            icon: <GppGoodIcon fontSize="small" />,
            route: '/compliances',
          },
          // {
          //   key: 'rfps',
          //   label: 'RFP',
          //   icon: <RequestQuoteIcon fontSize="small" />,
          //   route: '/rfps',
          // },
          // {
          //   key: 'projects',
          //   label: 'Projects',
          //   icon: <FolderCopyIcon fontSize="small" />,
          //   route: '/projects',
          // },
          // {
          {
            key: 'settings',
            label: 'Settings',
            icon: <TuneIcon fontSize="small" />,
            route: '/settings',
          }
        );
        break;
      case 'USER':
        assignedTabs.push(
          {
            key: 'dashboard',
            label: 'Dashboard',
            icon: <SpaceDashboardIcon fontSize="small" />,
            route: '/',
          },
          {
            key: 'companies',
            label: 'Companies',
            icon: <BusinessIcon fontSize="small" />,
            route: '/companies',
          },
          {
            key: 'leads',
            label: 'Leads',
            icon: <AccountTreeIcon fontSize="small" />,
            route: '/leads',
          },
          {
            key: 'followups',
            label: 'Events',
            icon: <GroupsIcon fontSize="small" />,
            route: '/followups',
          },
          {
            key: 'documents',
            label: 'Document Master',
            icon: <FileCopyIcon fontSize="small" />,
            route: '/documents',
          },
          {
            key: 'pqs',
            label: 'PQs',
            icon: <DescriptionIcon fontSize="small" />,
            route: '/pqs',
          },
          {
            key: 'rfqs',
            label: 'RFQs',
            icon: <RequestQuoteIcon fontSize="small" />,
            route: '/rfqs',
          },
          {
            key: 'rateanalysis',
            label: 'Rate Analysis',
            icon: <CurrencyRupeeIcon fontSize="small" />,
            route: '/rateanalysis',
          },
          {
            key: 'compliances',
            label: 'Compliances',
            icon: <GppGoodIcon fontSize="small" />,
            route: '/compliances',
          },
          // {
          //   key: 'rfps',
          //   label: 'RFP',
          //   icon: <RequestQuoteIcon fontSize="small" />,
          //   route: '/rfps',
          // },
          // {
          //   key: 'projects',
          //   label: 'Projects',
          //   icon: <FolderCopyIcon fontSize="small" />,
          //   route: '/projects',
          // },
          {
            key: 'settings',
            label: 'Settings',
            icon: <TuneIcon fontSize="small" />,
            route: '/settings',
          }
        );
        break;
      default:
        break;
    }

    return assignedTabs;
  }, [user]);

  const renderContent = () => {
    return (
      <>
        <Sidebar tabs={tabs} />
        <Box
          component="main"
          sx={{
            px: 2,
            pt: 1,
            width: '100%',
            height: '100%',
          }}
        >
          {props.children}
        </Box>
      </>
    );
  };

  return <Box sx={{ display: 'flex' }}>{renderContent()}</Box>;
};

export default ResponsiveSidebarLayout;
