import { gql } from '@apollo/client';

export const GET_ITEM_MASTER_QUERY = gql`
  query GetItemMasters($filter: ItemMasterFilter) {
    getItemMasters(filter: $filter) {
      _id
      referenceId
      description
      uom
      cost
    }
  }
`;
