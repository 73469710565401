import { Card, CardContent, CardProps, Grid, Tooltip, Typography } from '@mui/material';
import React from 'react';
import extendedTheme from 'styles/theme';
import { LeadSegmentEnum } from 'types';

import { getEnumKeyFromValue } from 'utils/transformFn';

type LeadCardProps = {
  title: string;
  segment?: LeadSegmentEnum;
} & CardProps;

const LeadCardTemplate: React.FC<LeadCardProps> = ({
  title,
  segment,
  children,
  sx = {},
  ...props
}) => {
  const assignSegmentIcons = () => {
    switch (segment) {
      case LeadSegmentEnum.HOSPITAL:
        return <img src="/segment-icons/hospital.svg" alt="cvav" width="80%" height="80%" />;
      case LeadSegmentEnum['DATA CENTER (DC)']:
        return <img src="/segment-icons/dc.svg" alt="cvav" width="90%" height="90%" />;
      case LeadSegmentEnum['EDUCATION INSTITUTE']:
        return <img src="/segment-icons/edu-institute.svg" alt="cvav" width="90%" height="90%" />;
      case LeadSegmentEnum['OFFICE SPACE (OS)']:
        return <img src="/segment-icons/os.svg" alt="cvav" width="90%" height="90%" />;
      case LeadSegmentEnum['RESEARCH LAB - LIFE SCIENCES']:
        return <img src="/segment-icons/pharma.svg" alt="cvav" width="90%" height="90%" />;
      case LeadSegmentEnum['SEMI CONDUCTOR']:
        return <img src="/segment-icons/semiconductor.svg" alt="cvav" width="90%" height="90%" />;
      default:
        return <img src="/segment-icons/hospital.svg" alt="cvavs" width="90%" height="90%" />;
    }
  };

  return (
    <Card
      variant="elevation"
      elevation={1}
      sx={{
        cursor: 'pointer',
        minHeight: '95px',
        position: 'relative',
        overflow: 'visible',
        borderRadius: '8px',
        boxShadow: extendedTheme.palette.boxshadow.gray,
        ...sx,
      }}
      {...props}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        position="relative"
        sx={{ pt: 1, px: 2 }}
      >
        <Grid item fontSize="1.1rem" maxWidth="80%">
          <Typography
            variant="inherit"
            fontWeight={600}
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'normal',
            }}
          >
            {title}
          </Typography>
        </Grid>
        {segment && (
          <Grid
            sx={{
              position: 'absolute',
              top: 5,
              right: 4,
              transform: 'translate(50%, -50%)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '37px',
              height: '37px',
              cursor: 'pointer',
              transition: 'opacity 0.3s ease-in-out',
              borderRadius: '50%',
              bgcolor: 'white',
              boxShadow: 4,
            }}
          >
            <Tooltip title={getEnumKeyFromValue(LeadSegmentEnum, segment)}>
              {assignSegmentIcons()}
            </Tooltip>
          </Grid>
        )}
      </Grid>

      <CardContent sx={{ py: 0, position: 'relative', minHeight: 35 }}>{children}</CardContent>
    </Card>
  );
};

export default LeadCardTemplate;
