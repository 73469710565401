import { gql } from '@apollo/client';

export const GET_UOM_CONVERSION_QUERY = gql`
  query GetUomConversions($filter: UomConversionFilter) {
    getUomConversions(filter: $filter) {
      _id
      referenceId
      boqUom
      raUom
      conversionFactor
    }
  }
`;

export type GetUomConversionsQueryResponse = {
  getUomConversions: any[];
  totalCount: number;
  totalPages: number;
  currentPage: number;
};

// export type GetUomConversionsQueryVariables = {
//   filter: {
//     searchTerm?: string;
//     limit: number | null;
//     page: number | null;
//   };
// };
