import { NetworkStatus, gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { FileDownload, FileUpload } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { GridRowModel } from '@mui/x-data-grid';
import { GET_RFQ_BY_ID_QUERY } from 'graphql/query/rfqs';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UOMEnum } from 'types';

import TextField, { NumberInputField } from 'components/Inputs/TextField';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import { SimplePopup } from 'components/Popup';
import UploadBOQExcel from 'components/RFQs/UploadBOQExcel';
import DataGridTable from 'components/Table';

import { getEnumKeyFromValue } from 'utils/transformFn';
import { processXLSFileContent } from 'utils/xlsProcesser';

const UPDATE_MULTIPLE_BOQS_MUTATION = gql`
  mutation UpdateMultipleBOQs($input: [UpdateMultipleBOQsInput]) {
    updateMultipleBOQs(input: $input) {
      _id
    }
  }
`;

export const DOWNLOAD_BOQS_EXCEL_FILE = gql`
  query DownloadBOQsExcelFile($lead: ID!) {
    downloadBOQsExcelFile(lead: $lead) {
      filename
      content
    }
  }
`;

const BulkUpdateBOQsPage = () => {
  const navigate = useNavigate();
  const isMobileScreen = useMediaQuery('(max-width:600px)');
  const { rfqId } = useParams<{ boqId: string; rfqId: string }>();

  const {
    data,
    networkStatus: boqNetworkStatus,
    refetch,
  } = useQuery<any>(GET_RFQ_BY_ID_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      id: rfqId,
    },
  });

  const boqsData = data?.getRFQById?.boqs;
  const loadingBOQDetails =
    boqNetworkStatus === NetworkStatus.loading ||
    boqNetworkStatus === NetworkStatus.setVariables ||
    !!!data;

  const [updateMultipleBOQs, { loading: updatingMultipleBOQs }] = useMutation(
    UPDATE_MULTIPLE_BOQS_MUTATION
  );

  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectedRowsMap, setSelectedRowsMap] = useState<Record<string, boolean>>({});

  const [globalState, setGlobalState] = useState<Record<string, any>>({});
  const [formTableState, setTableFormState] = useState<Record<string, any>>({});

  const handleChange = (fieldName: string, value: any) => {
    setGlobalState(prev => ({
      ...prev,
      [fieldName]: value,
    }));

    // Apply global value to selected rows
    setTableFormState(prevState => {
      const updatedState = { ...prevState };
      selectedRows.forEach(rowId => {
        updatedState[rowId] = {
          ...(updatedState[rowId] ?? {}),
          [fieldName]: value,
        };
      });
      return updatedState;
    });
  };

  const handleTableChange = (rowId: string, fieldName: string, value: any) => {
    setTableFormState(prev => {
      const updatedRow = {
        ...(prev[rowId] ?? {}),
        [fieldName]: value,
      };

      const updatedState = {
        ...prev,
        [rowId]: updatedRow,
      };

      // Synchronize rows with formTableState
      return updatedState;
    });
  };

  const handleProcessRowUpdate = (newRow: GridRowModel) => {
    setTableFormState(prevState => ({
      ...prevState,
      [newRow._id]: {
        ...(prevState[newRow._id] ?? {}),
        ...newRow,
      },
    }));

    return newRow;
  };

  const handleSubmit = () => {
    const updatedRows = boqsData
      .filter((row: any) => selectedRows.includes(row._id))
      .map((row: any) => ({
        // ...row,
        // ...formTableState[row._id],
        _id: row._id,
        margin: formTableState[row._id]?.margin
          ? parseFloat(formTableState[row._id].margin)
          : parseFloat(row.margin),
        discount: formTableState[row._id]?.discount
          ? parseFloat(formTableState[row._id].discount)
          : parseFloat(row.discount),
        wastage: formTableState[row._id]?.wastage
          ? parseFloat(formTableState[row._id].wastage)
          : parseFloat(row.wastage),
      }));

    updateMultipleBOQs({
      variables: {
        input: updatedRows,
      },
      onCompleted: res => {
        navigate(`/rfqs/${rfqId}/bulk-update`);
        window.location.reload();
      },
    });
  };

  const [downloadBOQsExcelFile, { loading: downloadingFolder }] = useLazyQuery(
    DOWNLOAD_BOQS_EXCEL_FILE,
    {
      variables: {
        lead: rfqId,
      },
      fetchPolicy: 'network-only',
    }
  );

  const handleDownloadFolder = () => {
    downloadBOQsExcelFile({
      onCompleted: res => {
        if (res?.downloadBOQsExcelFile) {
          processXLSFileContent(res.downloadBOQsExcelFile);
        }
      },
    });
  };

  const [openPopup, setOpenPopup] = useState(false);

  const handleUploadBoqs = () => {
    setOpenPopup(prev => !prev);
  };

  return (
    <>
      <Navbar
        goBackButtonConfig={{
          title: `Bulk Update BOQs`,
        }}
      >
        {loadingBOQDetails ? (
          <Grid item container direction="column" xs={12} mt={0.5} p={2}>
            <LoadingIndicator />
          </Grid>
        ) : (
          <Grid item container direction="column" xs={10} mt={0.5} p={2}>
            <form
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
              onSubmit={e => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <Grid container item xs={12} columnSpacing={2} rowSpacing={2}>
                <Grid
                  container
                  xs={12}
                  justifyContent={'end'}
                  // width={'100%'}
                  item
                  columnSpacing={2}
                  rowSpacing={2}
                  alignItems={'center'}
                >
                  {/* <Grid item xs={6} md={1.5} sm={4} justifyContent={'start'}>
                  <Tooltip title="Upload BOQs">
                    <IconButton>
                      {downloadingFolder ? (
                        <CircularProgress size={21} color="secondary" />
                      ) : (
                        <FileDownload color="secondary" onClick={handleDownloadFolder} />
                      )}
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Download BOQs">
                    <IconButton>
                      {downloadingFolder ? (
                        <CircularProgress size={21} color="secondary" />
                      ) : (
                        <FileUpload color="secondary" onClick={handleDownloadFolder} />
                      )}
                    </IconButton>
                  </Tooltip>
                </Grid> */}

                  <Grid item xs={4} md={1.5} sm={4}>
                    <NumberInputField
                      isPercentage
                      value={globalState?.margin ?? 0}
                      onChange={e => handleChange('margin', e.target.value)}
                      label="Margin %"
                      disabled={selectedRows.length < 2}
                    />
                  </Grid>
                  <Grid item xs={4} md={1.5} sm={4}>
                    <NumberInputField
                      isPercentage
                      value={globalState?.wastage ?? 0}
                      onChange={e => handleChange('wastage', e.target.value)}
                      label="Wastage %"
                      disabled={selectedRows.length < 2}
                    />
                  </Grid>
                  <Grid item xs={4} md={1.5} sm={4}>
                    <NumberInputField
                      isPercentage
                      value={globalState?.discount ?? 0}
                      onChange={e => handleChange('discount', e.target.value)}
                      label="Discount %"
                      disabled={selectedRows.length < 2}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <DataGridTable
                    sx={{
                      maxWidth: '90vw',
                      height: '450px',
                      minHeight: '600px',
                      mx: 'auto',
                      mt: 0.5,
                    }}
                    checkboxSelection
                    columns={[
                      {
                        field: 'referenceId',
                        headerName: 'Reference Id',
                        minWidth: 150,
                        disableColumnMenu: true,
                        disableReorder: true,
                      },
                      {
                        field: 'boqId',
                        headerName: 'BOQ Id',
                        minWidth: 150,
                        disableColumnMenu: true,
                        disableReorder: true,
                      },
                      {
                        field: 'name',
                        headerName: 'Name',
                        minWidth: 150,
                        disableColumnMenu: true,
                        disableReorder: true,
                      },
                      {
                        field: 'qty',
                        headerName: 'Quantity',
                        minWidth: 150,
                        disableColumnMenu: true,
                        disableReorder: true,
                      },
                      {
                        field: 'uom',
                        headerName: 'UOM',
                        minWidth: 50,
                        disableColumnMenu: true,
                        disableReorder: true,
                        sortable: false,
                        valueFormatter: (value: UOMEnum) => getEnumKeyFromValue(UOMEnum, value),
                      },
                      {
                        field: 'margin',
                        headerName: 'Margin %',
                        minWidth: 150,
                        disableColumnMenu: true,
                        disableReorder: true,
                        renderCell: params => (
                          <Box
                            sx={{
                              height: 50,
                              my: 'auto',
                              pt: 2,
                            }}
                          >
                            <NumberInputField
                              isPercentage
                              disabled={!selectedRowsMap[params?.row?._id]}
                              value={
                                formTableState[params?.row?._id]?.margin ?? params?.row?.margin ?? 0
                              }
                              onChange={e =>
                                handleTableChange(params?.row?._id, 'margin', e.target.value)
                              }
                              label=""
                              variant="standard"
                              size="small"
                            />
                          </Box>
                        ),
                      },

                      {
                        field: 'wastage',
                        headerName: 'Wastage %',
                        minWidth: 150,
                        disableColumnMenu: true,
                        disableReorder: true,
                        renderCell: params => (
                          <Box
                            sx={{
                              height: 50,
                              my: 'auto',
                              pt: 2,
                            }}
                          >
                            <NumberInputField
                              isPercentage
                              disabled={!selectedRowsMap[params?.row?._id]}
                              value={
                                formTableState[params?.row?._id]?.wastage ??
                                params?.row?.wastage ??
                                0
                              }
                              onChange={e =>
                                handleTableChange(params?.row?._id, 'wastage', e.target.value)
                              }
                              label=""
                              variant="standard"
                              size="small"
                            />
                          </Box>
                        ),
                      },
                      {
                        field: 'discount',
                        headerName: 'Discount %',
                        minWidth: 150,
                        disableColumnMenu: true,
                        disableReorder: true,
                        renderCell: params => (
                          <Box
                            sx={{
                              height: 50,
                              my: 'auto',
                              pt: 2,
                            }}
                          >
                            <NumberInputField
                              isPercentage
                              disabled={!selectedRowsMap[params?.row?._id]}
                              value={
                                formTableState[params?.row?._id]?.discount ??
                                params?.row?.discount ??
                                0
                              }
                              onChange={e =>
                                handleTableChange(params?.row?._id, 'discount', e.target.value)
                              }
                              label=""
                              variant="standard"
                              size="small"
                            />
                          </Box>
                        ),
                      },
                    ]}
                    getRowId={row => row._id}
                    rows={boqsData}
                    onRowSelectionModelChange={newSelection => {
                      setSelectedRowsMap(
                        newSelection.reduce((prev, curr) => {
                          prev[curr] = true;
                          return prev;
                        }, {})
                      );
                      setSelectedRows(newSelection as string[]);
                    }}
                    rowSelectionModel={selectedRows}
                    processRowUpdate={handleProcessRowUpdate}
                    hideFooterPagination
                  />
                </Grid>

                <Grid item container justifyContent={'space-between'} gap={1} alignItems={'center'}>
                  <Grid
                    item
                    gap={1}
                    xs={8}
                    container
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                  >
                    <Button
                      startIcon={<FileUpload />}
                      size={isMobileScreen ? 'small' : 'medium'}
                      variant="contained"
                      sx={{ mr: 1 }}
                      onClick={handleUploadBoqs}
                    >
                      Upload BOQs
                    </Button>

                    {downloadingFolder ? (
                      <CircularProgress size={21} color="secondary" />
                    ) : (
                      <Button
                        startIcon={<FileDownload />}
                        size={isMobileScreen ? 'small' : 'medium'}
                        variant="contained"
                        onClick={handleDownloadFolder}
                        title="Download BOQs"
                      >
                        Download BOQs
                      </Button>
                    )}
                  </Grid>
                  <Grid item justifyContent={'flex-end'}>
                    <LoadingButton
                      variant="contained"
                      size={isMobileScreen ? 'small' : 'medium'}
                      type="submit"
                      color="secondary"
                      sx={{ whiteSpace: 'nowrap' }}
                      loading={updatingMultipleBOQs}
                      disabled={!selectedRows.length}
                    >
                      Update BOQ
                    </LoadingButton>
                    {/* {updatingMultipleBOQs && (
                      <CircularProgress
                        size={50}
                        color="secondary"
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: '-12px',
                          marginLeft: '-12px',
                        }}
                      />
                    )} */}
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        )}
      </Navbar>

      {openPopup && (
        <SimplePopup title="Upload BOQ" open={openPopup} onClose={() => setOpenPopup(false)}>
          <UploadBOQExcel refetchRFQ={refetch} onCancel={() => setOpenPopup(false)} />
        </SimplePopup>
      )}
    </>
  );
};

export default BulkUpdateBOQsPage;
