import { useMutation, useQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Grid, InputAdornment } from '@mui/material';
import {
  CREATE_BOQ_MUTATION,
  CreateBOQMutationResponse,
  CreateBOQMutationVariables,
  UPDATE_BOQ_MUTATION,
  UpdateBOQMutationResponse,
  UpdateBOQMutationVariables,
} from 'graphql/mutation/boq';
import { GET_BOQ_BYID_QUERY } from 'graphql/query/boq';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UOMEnum } from 'types';

import { Select } from 'components/Inputs/Select';
import TextField, { NumberInputField } from 'components/Inputs/TextField';
import Navbar from 'components/Navbar';

import { removeEmptyFields } from 'utils/common';

const ManageBOQ = () => {
  const navigate = useNavigate();
  const queryString = useLocation()?.search;
  const params = new URLSearchParams(queryString);

  const type = params.get('type');
  const rfqId = params.get('rfqId');
  const boqId = params.get('boqId');
  const rfqStatus = params.get('rfqStatus');
  const screenTitle = type === 'create' ? 'Create BOQ' : 'Update BOQ';

  const [formState, setFormState] = useState<Record<string, any>>({});

  // Get BOQ details by id
  const { data } = useQuery<any>(GET_BOQ_BYID_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    skip: !boqId,
    variables: {
      id: boqId,
    },
  });

  const boqDetails = data?.getBOQById;

  useEffect(() => {
    if (boqDetails) {
      setFormState({
        boqId: boqDetails?.boqId,
        name: boqDetails?.name,
        description: boqDetails?.description,
        qty: boqDetails?.qty,
        uom: boqDetails?.uom,
        wastage: boqDetails?.wastage,
        discount: boqDetails?.discount,
        margin: boqDetails?.margin,
        cost: boqDetails?.cost,
        price: boqDetails?.price,
      });
    }
  }, [boqDetails]);

  const [createBOQ, { loading: creatingBOQ }] = useMutation<
    CreateBOQMutationResponse,
    CreateBOQMutationVariables
  >(CREATE_BOQ_MUTATION);

  const [updateBOQ, { loading: updatingBOQ }] = useMutation<
    UpdateBOQMutationResponse,
    UpdateBOQMutationVariables
  >(UPDATE_BOQ_MUTATION);

  const handleChange = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleSubmit = () => {
    const refinedData = removeEmptyFields({ ...formState });
    if (type === 'create') {
      createBOQ({
        variables: {
          input: {
            ...refinedData,
            lead: rfqId,
            discount: Number(formState.discount),
            margin: Number(formState.margin),
            cost: Number(formState.cost),
            // price: Number(formState.price),
            wastage: Number(formState.wastage),
            qty: Number(formState.qty),
          },
        },
        onCompleted: res => navigate(`/rfqs/${rfqId}`, { replace: true }),
      });
    } else {
      updateBOQ({
        variables: {
          input: {
            _id: boqId,
            lead: rfqId,
            name: String(formState.name),
            description: String(formState.description),
            uom: formState.uom,
            boqId: String(formState.boqId),
            cost: Number(formState.cost),
            discount: formState.discount ? Number(formState.discount) : null,
            margin: formState.margin ? Number(formState.margin) : null,
            wastage: formState.wastage ? Number(formState.wastage) : null,
            qty: Number(formState.qty),
          },
        },
        onCompleted: res =>
          navigate(`/rfqs/${rfqId}/${boqId}?rfqStatus=${rfqStatus}`, { replace: true }),
      });
    }
  };

  return (
    <Navbar
      goBackButtonConfig={{
        title: screenTitle,
      }}
    >
      <Grid item container direction="column" xs={12} md={11} lg={9} xl={6} mt={0.5} rowGap={2.5}>
        <form
          onSubmit={e => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Grid container spacing={2}>
            <Grid container item xs={12} columnSpacing={2} rowSpacing={2}>
              <Grid item xs={6}>
                <TextField
                  required
                  value={formState.boqId ?? ''}
                  onChange={e => handleChange('boqId', e.target.value)}
                  label="BOQ ID"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  value={formState.name ?? ''}
                  onChange={e => handleChange('name', e.target.value)}
                  label="BOQ Name"
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                multiline
                minRows={3}
                value={formState.description ?? ''}
                onChange={e => handleChange('description', e.target.value)}
                label="BOQ Description"
              />
            </Grid>
            <Grid container item xs={12} columnSpacing={2} rowSpacing={2}>
              <Grid item xs={6}>
                <Select
                  name="uom"
                  label="UOM"
                  options={Object.keys(UOMEnum).map(o => ({
                    label: o,
                    value: UOMEnum[o],
                  }))}
                  value={formState.uom ?? ''}
                  onChange={val => handleChange('uom', val)}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <NumberInputField
                  required
                  value={formState.qty ?? ''}
                  onChange={e => handleChange('qty', e.target.value)}
                  label="Quantity"
                />
              </Grid>
            </Grid>
            {screenTitle === 'Update BOQ' && (
              <Grid container item xs={12} columnSpacing={2} rowSpacing={2}>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    value={formState?.cost?.toFixed(2) ?? ''}
                    label="Cost"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <b color="lightGray">₹</b>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    value={formState?.price?.toFixed(2) ?? ''}
                    label="Price"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <b color="lightGray">₹</b>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid container item xs={12} columnSpacing={2} rowSpacing={2}>
                  <Grid item xs={6}>
                    <NumberInputField
                      value={formState.margin ?? ''}
                      onChange={e => handleChange('margin', e.target.value)}
                      label="Margin %"
                      type="number"
                      isPercentage
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <NumberInputField
                      value={formState.wastage ?? ''}
                      onChange={e => handleChange('wastage', e.target.value)}
                      label="Wastage %"
                      type="number"
                      isPercentage
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <NumberInputField
                      value={formState.discount ?? ''}
                      onChange={e => handleChange('discount', e.target.value)}
                      label="Discount %"
                      type="number"
                      isPercentage
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item xs={12} alignSelf="center" container justifyContent="end">
              <LoadingButton
                variant="contained"
                size="medium"
                type="submit"
                color="secondary"
                loading={creatingBOQ || updatingBOQ}
              >
                {screenTitle}
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Navbar>
  );
};

export default ManageBOQ;
