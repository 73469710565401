import { useQuery } from '@apollo/client';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { Grid, Tooltip } from '@mui/material';
import { GET_BOQ_BYID_QUERY } from 'graphql/query/boq';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UOMEnum } from 'types';
import { LeadStatusEnum } from 'types';

import Section, { SectionDataProps } from 'components/Section';

import { extractLastSegment } from 'utils/formatHelper';
import { getEnumKeyFromValue } from 'utils/transformFn';

import RAMappingSection from './RAMapping';

const BOQSection: React.FC<{
  rfqId: string;
}> = ({ rfqId }) => {
  const queryString = useLocation()?.search;
  const params = new URLSearchParams(queryString);
  const rfqStatus = params.get('rfqStatus') as string;

  const { data } = useQuery<any>(GET_BOQ_BYID_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      id: extractLastSegment(window.location.href),
    },
  });

  const boqDetails = data?.getBOQById;

  const getTransformedValue = (value: any) => {
    return Number(value) >= 0 && value !== null ? Number(value)?.toFixed(2) : '-';
  };

  const details: SectionDataProps[] = [
    {
      label: 'BOQ Id',
      value: boqDetails?.boqId,
      type: 'STRING',
    },
    {
      label: 'Name',
      value: boqDetails?.name,
      type: 'STRING',
    },
    {
      label: 'Description',
      value: boqDetails?.description,
      type: 'STRING',
    },
    {
      label: 'Quantity',
      value: boqDetails?.qty ?? 'N/A',
      type: 'STRING',
    },
    {
      label: 'UOM',
      value: getEnumKeyFromValue(UOMEnum, boqDetails?.uom),
      type: 'STRING',
    },
    {
      label: 'Wastage %',
      value: getTransformedValue(boqDetails?.wastage),
      type: 'STRING',
    },
    {
      label: 'Margin %',
      value: getTransformedValue(boqDetails?.margin),
      type: 'STRING',
    },
    {
      label: 'Discount %',
      value: getTransformedValue(boqDetails?.discount),
      type: 'STRING',
    },
    {
      label: 'Price',
      value: boqDetails?.price ? boqDetails?.price?.toFixed(2) : '0',
      type: 'STRING',
    },
    {
      label: 'Cost',
      value: boqDetails?.cost ? boqDetails?.cost?.toFixed(2) : '0',
      type: 'STRING',
    },
  ];

  const navigate = useNavigate();
  return (
    <Grid container direction="column" rowGap={1.5} mt={0}>
      <Section
        title="BOQ Details"
        collapsible
        data={details}
        action={
          rfqStatus === LeadStatusEnum['RFP RECEIVED'] && (
            <Tooltip title="Edit BOQ details">
              <EditNoteIcon
                onClick={() => {
                  navigate(
                    `/rfqs/manage-boq?type=edit&rfqId=${rfqId}&boqId=${boqDetails?._id}&rfqStatus=${rfqStatus}`
                  );
                }}
                sx={{ cursor: 'pointer' }}
              />
            </Tooltip>
          )
        }
      />

      <RAMappingSection
        rfqId={rfqId}
        rfqStatus={rfqStatus}
        items={boqDetails?.rateAnalysis?.items}
        isEdit={boqDetails?.rateAnalysis?._id}
        rateAnalysisId={boqDetails?.rateAnalysis?._id}
      />
    </Grid>
  );
};

export default BOQSection;
