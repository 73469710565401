import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import UploadFile from 'components/Inputs/UploadFile';

export const UPLOAD_BOQ_EXCEL_MUTATION = gql`
  mutation BulkUpdateBOQsFromExcel($lead: ID!, $input: GraphQLUpload!) {
    bulkUpdateBOQsFromExcel(lead: $lead, input: $input) {
      message
      totalRecords
      errors
      successfulRecords
      failedRecords
    }
  }
`;

const UploadBOQExcel: React.FC<{
  onCancel: () => void;
  refetchRFQ?: any;
}> = ({ onCancel, refetchRFQ }) => {
  const [formState, setFormState] = useState<Record<string, any>>({});
  const { rfqId = '' } = useParams<{ rfqId: string }>();
  const [uploadBOQs, { loading: uploadingBOQs }] = useMutation<any, any>(UPLOAD_BOQ_EXCEL_MUTATION);

  const handleChange = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleSubmit = () => {
    uploadBOQs({
      variables: {
        lead: rfqId,
        input: formState.file,
      },
      onCompleted: res => {
        const result = res.bulkUpdateBOQsFromExcel;
        if (result.errors && result.errors.length > 0) {
          toast.error(result.message || 'An error occurred.');
        } else {
          toast.success(result.message || 'Operation completed successfully.');
        }
        onCancel();
        refetchRFQ();
      },
    });
  };

  return (
    <form
      encType="multipart/form-data"
      method="post"
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container columnSpacing={1.5} rowGap={2} p={2} xs={12}>
        <Grid item xs={12}>
          <UploadFile
            buttonProps={{
              size: 'small',
            }}
            values={formState.file ?? ''}
            onChange={val => handleChange('file', val)}
            label="Select File *"
            required
          />
        </Grid>

        <Grid item container xs={12} columnGap={1} justifyContent={'flex-end'}>
          <Button variant="text" size="medium" onClick={onCancel}>
            Cancel
          </Button>

          <LoadingButton variant="contained" loading={uploadingBOQs} type="submit" size="medium">
            Upload
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default UploadBOQExcel;
