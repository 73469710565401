import { gql, useMutation } from '@apollo/client';
import { Grid, Typography } from '@mui/material';
import React from 'react';
import { UOMEnum } from 'types';

import { FormInput, FormPanel } from 'components/FormPanel';
import { Select } from 'components/Inputs/Select';
import Note from 'components/Note';
import { SimplePopup } from 'components/Popup';

import { getEnumKeyFromValue } from 'utils/transformFn';

const UPDATE_UOM_CONVERSION_MUTATION = gql`
  mutation UpdateUomConversion($input: UpdateUomConversionInput) {
    updateUomConversion(input: $input) {
      _id
      boqUom
      conversionFactor
      raUom
      referenceId
    }
  }
`;

type UpdateUomConversionMutationResponse = {
  updateUomConversion: any;
};
type UpdateUomConversionMutationVariables = {
  input: {
    _id: string;
    boqUom?: any;
    conversionFactor?: any;
    raUom?: any;
  };
};

type UpdateConversionFormProps = {
  conversion: any;
  openForm: boolean;
  toggleForm: (arg: boolean) => void;
};

const UpdateConversionForm: React.FC<UpdateConversionFormProps> = ({
  conversion,
  openForm,
  toggleForm,
}) => {
  const [updateConversion, { loading: updatingConversion }] = useMutation<
    UpdateUomConversionMutationResponse,
    UpdateUomConversionMutationVariables
  >(UPDATE_UOM_CONVERSION_MUTATION);

  const handleSubmit = (data: any) => {
    updateConversion({
      variables: {
        input: {
          _id: conversion._id,
          boqUom: data.boqUom,
          conversionFactor: Number(data.conversionFactor),
          raUom: data.raUom,
        },
      },
      onCompleted: () => toggleForm(false),
    });
  };

  return (
    <SimplePopup
      onClose={() => toggleForm(false)}
      open={openForm}
      title="Update Conversion"
      fullWidth
    >
      <Grid container direction="column" rowGap={2} px={2} pb={2}>
        <Note title="Conversion is from BOQ UOM to RA UOM" />
        <FormPanel
          error={null}
          loading={updatingConversion}
          onSubmit={handleSubmit}
          submitButtonLabel="Update"
        >
          <FormInput
            fieldName="boqUom"
            defaultValue={conversion.boqUom}
            options={Object.keys(UOMEnum).map(o => ({ label: o, value: UOMEnum[o] }))}
            label="BOQ UOM"
            type="select"
            validators={{
              required: true,
            }}
          />
          <FormInput
            fieldName="raUom"
            defaultValue={conversion.raUom}
            options={Object.keys(UOMEnum).map(o => ({ label: o, value: UOMEnum[o] }))}
            label="RA UOM"
            type="select"
            validators={{
              required: true,
            }}
          />
          <FormInput
            fieldName="conversionFactor"
            defaultValue={conversion.conversionFactor}
            label="Conversion Factor"
            type="number"
            fullWidth
            validators={{
              required: true,
            }}
          />
        </FormPanel>
      </Grid>
    </SimplePopup>
  );
};

export default UpdateConversionForm;
