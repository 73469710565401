import { gql, useLazyQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import EditNoteIcon from '@mui/icons-material/EditNote';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ShareIcon from '@mui/icons-material/Share';
import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LeadStatusEnum } from 'types';

import { SimplePopup } from 'components/Popup';
import UploadBOQForm from 'components/RFQs/UploadBOQForm';
import ShareRFQForm from 'components/RFQs/forms/ShareRFQForm';
import SubmitRFQForm from 'components/RFQs/forms/SubmitRFQForm';
import Section from 'components/Section';
import Transition from 'components/Transition';

import { processXLSFileContent } from 'utils/xlsProcesser';

import { BOQCreationTable } from './BOQCreationTable';

export const DOWNLOAD_BOQS_DETAILS = gql`
  query DownloadBOQsDetails($lead: ID!) {
    downloadBOQsDetails(lead: $lead) {
      content
      filename
    }
  }
`;

const BOQCreationSection: React.FC<{
  rfqId: string;
  rfq: any;
  refetchRFQ: any;
}> = ({ rfqId, rfq, refetchRFQ }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const boqsData = rfq?.boqs;

  const [enableShareBOQForm, toggleShareBOQForm] = useState(false);
  const [enableSubmitForm, toggleSubmitForm] = useState(false);

  const isMobileScreen = useMediaQuery('(max-width: 600px)');

  const disableRFQSubmission = boqsData?.some((boq: any) => boq?.rateAnalysis === null);

  const [downloadBOQsDetails, { loading: downloadingFolder }] = useLazyQuery(
    DOWNLOAD_BOQS_DETAILS,
    {
      variables: {
        lead: rfqId,
      },
      fetchPolicy: 'network-only',
    }
  );

  const handleDownloadFolder = () => {
    downloadBOQsDetails({
      onCompleted: res => {
        if (res?.downloadBOQsDetails) {
          processXLSFileContent(res.downloadBOQsDetails);
        }
      },
    });
  };
  const handleDownload = () => {
    const fileUrl = `${process.env.PUBLIC_URL}/files/boq_template.xlsx`;

    // Create a link element to trigger download
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = 'boq_template.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Section
      title="BOQ Table"
      defaultCollapsed
      collapsible
      action={
        <>
          <Tooltip title="Download BOQ Data">
            <DownloadForOfflineIcon
              onClick={handleDownloadFolder}
              sx={{ cursor: 'pointer', mr: 1 }}
            />
          </Tooltip>

          {rfq?.leadStatus === LeadStatusEnum['RFP SUBMITTED'] && (
            <Tooltip title="Share BOQ">
              <ShareIcon
                onClick={() => toggleShareBOQForm(true)}
                sx={{ cursor: 'pointer', mr: 1 }}
                fontSize="small"
              />
            </Tooltip>
          )}

          {rfq?.leadStatus === LeadStatusEnum['RFP RECEIVED'] && (
            <>
              <Tooltip title="Update BOQs Margin, Discount and Wastage in Bulk">
                <EditNoteIcon
                  onClick={() => navigate(`/rfqs/${rfqId}/bulk-update`)}
                  sx={{ cursor: 'pointer', mr: 1 }}
                />
              </Tooltip>

              <Tooltip title="Bulk Upload BOQs">
                <FileUploadIcon
                  onClick={() => {
                    setOpen(true);
                  }}
                  sx={{ cursor: 'pointer', mr: 1 }}
                />
              </Tooltip>

              <Tooltip title="Download BOQ Template">
                <FileDownloadIcon onClick={handleDownload} sx={{ cursor: 'pointer', mr: 1 }} />
              </Tooltip>

              <Tooltip title="Create BOQ">
                <AddIcon
                  onClick={() => navigate(`/rfqs/manage-boq?type=create&rfqId=${rfqId}`)}
                  sx={{ cursor: 'pointer' }}
                />
              </Tooltip>
            </>
          )}
        </>
      }
    >
      <Dialog
        open={enableShareBOQForm}
        TransitionComponent={Transition}
        fullWidth
        fullScreen={isMobileScreen}
        onClose={(_, reason) => {
          if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) return;
          toggleShareBOQForm(false);
        }}
      >
        <AppBar sx={{ position: 'relative', bgcolor: theme => theme.palette.secondary.main }}>
          <Toolbar variant={'dense'}>
            <Typography sx={{ flex: 1, fontWeight: 600 }} variant="subtitle1" component="div">
              Mail BOQ Details
            </Typography>
            <CloseIcon onClick={() => toggleShareBOQForm(false)} sx={{ cursor: 'pointer' }} />
          </Toolbar>
        </AppBar>

        <DialogContent>
          <ShareRFQForm lead={rfq} cb={() => toggleShareBOQForm(false)} />
        </DialogContent>
      </Dialog>
      <SimplePopup
        onClose={() => toggleSubmitForm(false)}
        open={enableSubmitForm}
        title="Submit BOQ"
        fullWidth
      >
        <SubmitRFQForm
          lead={rfq}
          cb={() => {
            toggleSubmitForm(false);
            refetchRFQ();
          }}
        />
      </SimplePopup>

      <BOQCreationTable boqs={boqsData} rfqId={rfqId} rfqStatus={rfq?.leadStatus} />
      <SimplePopup onClose={() => setOpen(false)} open={open} title="Upload BOQs" fullWidth>
        <UploadBOQForm onCancel={() => setOpen(false)} refetchRFQ={refetchRFQ} />
      </SimplePopup>
      {rfq?.leadStatus === LeadStatusEnum['RFP RECEIVED'] && (
        <Grid display={'flex'} justifyContent={'flex-end'} pt={1}>
          <Button
            variant="contained"
            size="small"
            onClick={() => toggleSubmitForm(true)}
            disabled={disableRFQSubmission}
          >
            Submit BOQ
          </Button>
        </Grid>
      )}
    </Section>
  );
};

export default BOQCreationSection;
