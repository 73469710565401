import { gql } from '@apollo/client';

export const GET_RFQ_VALIDATION_BY_LEADID = gql`
  query GetRFQValidationByLeadId($leadId: ID!) {
    getRFQValidationByLeadId(leadId: $leadId) {
      _id
      referenceId
      lead {
        name
        _id
        # company {
        #   name
        #   type
        # }
      }
      totalAreaForWork
      uom
      approxWorkValue
      executionTime
      advance
      onSupply
      ra
      handoverPercentage
      dlpYears
      dlpRetentionPercentage
      totalHandoverPercentage
      timeToDesign
      designRequirement
      designInvestmentRequired
      emd
      abg
      pbg
      otherBG
      paymentTermsNegotiable
      comments
      reasonForNogo
    }
  }
`;
