import { NetworkStatus, useQuery } from '@apollo/client';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import { Box, Button, Grid, IconButton, Tooltip, useMediaQuery } from '@mui/material';
import { orange } from '@mui/material/colors';
import { GET_RFQS_QUERY, GetPQsQueryResponse, GetPQsQueryVariables } from 'graphql/query/rfqs';
import { useState } from 'react';

import Filters from 'components/Filters';
import { Sort } from 'components/Leads/Sort/Sort';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import RFQKanbanView from 'components/RFQs/RFQKanbanView';
import RFQListView, { RFQStatusEnum } from 'components/RFQs/RFQListView';

import { transformFilters } from 'utils/transformFn';

type RFQViewType = 'list' | 'kanban';

const RFQPage = () => {
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [rfqType, setRfqType] = useState(RFQStatusEnum.CREATED);
  const [viewType, setViewType] = useState<RFQViewType>('kanban');

  const isMobileScreen = useMediaQuery('(max-width:600px)');

  const {
    data: rfqs,
    networkStatus,
    refetch: refetchLeads,
  } = useQuery<GetPQsQueryResponse, GetPQsQueryVariables>(GET_RFQS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
    refetchLeads({
      filter: newFilters,
    });
  };

  const handleViewChange = (view: RFQViewType) => {
    setViewType(view);
  };

  const loadingLeads =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!rfqs;

  return (
    <Navbar
      title="RFQ's"
      searchInputConfig={{
        enable: true,
        name: 'RFQ search',
        placeholder: 'ID / Name',
        handleChange: searchFilter =>
          applyFilters({
            ...filters,
            searchTerm: !!searchFilter ? searchFilter : null,
          }),
      }}
      actionChildren={
        <>
          <Grid display="flex" alignItems="center" justifyContent={'flex-end'}>
            <IconButton onClick={() => handleViewChange('list')} color="secondary">
              <Tooltip title="List View">
                {viewType === 'list' ? (
                  <ViewListOutlinedIcon
                    fontSize="medium"
                    sx={{
                      backgroundColor: orange[200],
                      borderRadius: '4px',
                      padding: '4px',
                    }}
                  />
                ) : (
                  <ViewListOutlinedIcon
                    fontSize="medium"
                    sx={{
                      borderRadius: '4px',
                      padding: '4px',
                    }}
                  />
                )}
              </Tooltip>
            </IconButton>
            <IconButton onClick={() => handleViewChange('kanban')} color="secondary">
              <Tooltip title="Kanban View">
                {viewType === 'kanban' ? (
                  <ViewKanbanIcon
                    fontSize="small"
                    sx={{
                      backgroundColor: orange[200],
                      borderRadius: '4px',
                      padding: '4px',
                    }}
                  />
                ) : (
                  <ViewKanbanIcon
                    fontSize="small"
                    sx={{
                      borderRadius: '4px',
                      padding: '4px',
                    }}
                  />
                )}
              </Tooltip>
            </IconButton>
          </Grid>
        </>
      }
    >
      {isMobileScreen && viewType === 'kanban' ? (
        <Box
          display={'flex'}
          sx={{
            overflowX: 'scroll',
            width: '94vw',
            alignItems: 'start',
            gap: '0.5rem',
            height: '4vh',
            mr: -9,

            // flexWrap: 'nowrap',
          }}
        >
          <Button
            variant="contained"
            onClick={() => setRfqType(RFQStatusEnum.CREATED)}
            color="secondary"
            fullWidth
            size="small"
            sx={{
              whiteSpace: 'nowrap',
              maxWidth: '120px',
              flexShrink: 0,
              textOverflow: 'ellipsis',
            }}
          >
            Created
          </Button>

          <Button
            variant="contained"
            onClick={() => setRfqType(RFQStatusEnum['APPROVAL IN PROGRESS'])}
            color="secondary"
            fullWidth
            size="small"
            sx={{
              whiteSpace: 'nowrap',
              flexShrink: 0,
              textOverflow: 'ellipsis',
              maxWidth: '180px',
            }}
          >
            Approval In Progress
          </Button>

          <Button
            variant="contained"
            onClick={() => setRfqType(RFQStatusEnum.APPROVED)}
            color="secondary"
            fullWidth
            size="small"
            sx={{
              whiteSpace: 'nowrap',
              maxWidth: '120px',
              flexShrink: 0,
              textOverflow: 'ellipsis',
            }}
          >
            Approved
          </Button>

          <Button
            variant="contained"
            onClick={() => setRfqType(RFQStatusEnum.REJECTED)}
            color="secondary"
            fullWidth
            size="small"
            sx={{
              whiteSpace: 'nowrap',
              maxWidth: '120px',
              flexShrink: 0,
              textOverflow: 'ellipsis',
            }}
          >
            Rejected
          </Button>
        </Box>
      ) : (
        <Grid
          container
          justifyContent="flex-end"
          columnSpacing={2}
          mb={2}
          pr={isMobileScreen ? 0 : 2}
          pt={isMobileScreen ? 0.5 : 0}
        >
          <Grid item xs={6} md={1.8} xl={1}>
            <Filters
              type="pqs"
              refetch={data => {
                applyFilters(transformFilters(data, ['city', 'company']));
              }}
            />
          </Grid>
          <Grid item xs={6} md={1.5} xl={1}>
            <Sort
              sort={filters.sortOption}
              setSort={newSortVal =>
                applyFilters({
                  ...filters,
                  sortOption: newSortVal,
                })
              }
            />
          </Grid>
        </Grid>
      )}

      {loadingLeads ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Grid>
          {viewType === 'list' ? (
            <RFQListView rfqs={rfqs.getRFQs} rfqType={rfqType} />
          ) : (
            <RFQKanbanView rfqs={rfqs.getRFQs} rfqType={rfqType} />
          )}
        </Grid>
      )}
    </Navbar>
  );
};

export default RFQPage;
